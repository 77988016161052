export enum ProtectedRoutes {
  DASHBOARD = '/',
  HIVE = '/my-hive',
  SUPPLIERS = '/my-suppliers',
  CUSTOMERS = '/my-customers',
  PRODUCTS = '/my-products',
  INSIGHTS = '/my-insights',
  NEWS_AND_SUPPORT = '/support',
}

export enum Routes {
  SIGNIN = '/sign-in',
}

export const RouteTitle = {
  [ProtectedRoutes.DASHBOARD]: 'Dashboard',
  [ProtectedRoutes.HIVE]: 'My Hive',
  [ProtectedRoutes.SUPPLIERS]: 'My Suppliers',
  [ProtectedRoutes.CUSTOMERS]: 'My Customers',
  [ProtectedRoutes.PRODUCTS]: 'My Products',
  [ProtectedRoutes.INSIGHTS]: 'My Hive Insights',
  [ProtectedRoutes.NEWS_AND_SUPPORT]: 'News and Support',
};
