import {
  Button,
  DatePicker,
  Form,
  FormInstance,
  InputNumber,
  Select,
  Tag,
  Tooltip,
  Typography,
} from 'antd';
import { DefaultOptionType } from 'antd/es/select';
import { ColumnsType } from 'antd/es/table';
import { ColumnFilterItem } from 'antd/es/table/interface';
import clsx from 'clsx';
import dayjs from 'dayjs';
import { IconCheck } from 'src/components/icons/check/IconCheck';
import { IconClose } from 'src/components/icons/close/IconClose';
import { IconDate } from 'src/components/icons/date/IconDate';
import { IconEdit } from 'src/components/icons/edit/IconEdit';
import { IconFilter } from 'src/components/icons/filter/IconFilter';
import { IconHistory } from 'src/components/icons/history/IconHistory';
import { IconSort, SortOrder } from 'src/components/icons/sort/IconSort';
import { IconUnlink } from 'src/components/icons/unlink/IconUnlink';
import Cell from 'src/components/table/cell/Cell';
import TableFilters from 'src/components/table/filters/TableFilters';
import Highlighter from 'src/components/table/highlighter/Highlighter';
import { BROADCASTNAME } from 'src/constants/broadcast';
import {
  ActionType,
  ModalContextActions,
  TModalContextStateAction,
} from 'src/contexts/modal-context';
import { TEditAssignedProduct } from 'src/hooks/use-companies';
import styles from 'src/styles/actions.module.scss';
import { IMappedProductOffer, IProductOffer } from 'src/types/general';
import { ArrayElement } from 'src/types/generic';
import { formatDate } from './date-formatter';
import { formatNumber } from './number-formatter';

const properties: (keyof IMappedProductOffer | 'actions')[] = [
  'product.name',
  'product.isDeleted',
  'productOfferPrice.price',
  'productOfferPrice.currency',
  'quantity',
  'unitOfMeasurement',
  'productOfferPrice.startDate',
  'productOfferPrice.expirationDate',
  'createdAt',
  'product.materialNumber',
  'product.partNumber',
  'product.barCode',
  'actions',
];

const propertiesWithSupplier: (keyof IMappedProductOffer)[] = [
  'product.name',
  'product.isDeleted',
  'supplier.name',
  'createdAt',
  'productOfferPrice.price',
  'productOfferPrice.currency',
  'quantity',
  'unitOfMeasurement',
  'productOfferPrice.startDate',
  'productOfferPrice.expirationDate',
  'product.materialNumber',
  'product.partNumber',
  'product.barCode',
];

export type TMappedProductOfferRecord = Omit<
  IMappedProductOffer,
  'canEdit' | 'canDelete' | 'id'
> & {
  key: string;
  actions: Pick<IProductOffer, 'canDelete' | 'canEdit'>;
  isEditing: boolean;
};

type TGetAssignedProductColumnsParams = {
  modalDispatch?: React.Dispatch<TModalContextStateAction>;
  setEditRow?: (index: number, record: TMappedProductOfferRecord) => void;
  setLoading?: (value: boolean) => void;
  unsetEditRow?: (index: number) => void;
  edit?: TEditAssignedProduct;
  search?: string;
  searchLoading?: boolean;
  isFilterSearch?: boolean;
  withSupplier?: boolean;
  customerId?: string;
  isHistoryAction?: boolean;
  actionsHidden: boolean;
  isEditing?: boolean;
  currencies: DefaultOptionType[];
  uoms: DefaultOptionType[];
  form?: FormInstance;
  filters?: ColumnFilterItem[];
  setFilterSearch?: (value: string) => void;
  showErrorMessage?: (text: string) => void;
  showSuccessMessage?: (text: string) => void;
};

export const getMappedProductOffersData = (
  data: IProductOffer[]
): TMappedProductOfferRecord[] => {
  return data.map((row) => {
    const res: TMappedProductOfferRecord = {
      key: row.id,
      'product.name': row.product.name,
      'product.isDeleted': row.product.isDeleted,
      'supplier.name': row.supplier.name,
      'productOfferPrice.price': row.productOfferPrice.price,
      'productOfferPrice.currency': row.productOfferPrice.currency,
      quantity: row.quantity,
      unitOfMeasurement: row.unitOfMeasurementId,
      'productOfferPrice.startDate': row.productOfferPrice.startDate,
      'productOfferPrice.expirationDate': row.productOfferPrice.expirationDate,
      createdAt: row.createdAt,
      'product.materialNumber': row.product.materialNumber,
      'product.partNumber': row.product.partNumber,
      'product.barCode': row.product.barCode,
      actions: {
        canDelete: row.canDelete,
        canEdit: row.canEdit,
      },
      isEditing: false,
    };
    return res;
  });
};

export const getAssignedProductsColumnSetting = function ({
  modalDispatch,
  setEditRow,
  unsetEditRow,
  edit,
  customerId,
  actionsHidden,
  setLoading,
  currencies,
  uoms,
  isEditing,
  form,
  showSuccessMessage,
  showErrorMessage,
  isHistoryAction,
  search,
  withSupplier,
  filters,
  setFilterSearch,
  isFilterSearch,
  searchLoading,
}: TGetAssignedProductColumnsParams) {
  const columns = withSupplier ? propertiesWithSupplier : properties;
  const result: ColumnsType<TMappedProductOfferRecord> = columns.map(
    (property): ArrayElement<ColumnsType<TMappedProductOfferRecord>> => {
      switch (property) {
        case 'product.name':
          return {
            title: 'Product name',
            width: 200,
            key: property,
            fixed: 'left',
            ellipsis: true,
            dataIndex: property,
            sorter: true,
            showSorterTooltip: false,
            sortIcon: (props) => (
              <IconSort
                width="16"
                height="16"
                order={props.sortOrder as SortOrder}
              />
            ),
            render: (value) => {
              return (
                <Cell isValue={!!value}>
                  <Highlighter value={value} search={search || ''} />
                </Cell>
              );
            },
          };
        case 'product.isDeleted':
          return {
            title: 'Product status',
            width: 134,
            ellipsis: true,
            key: property,
            dataIndex: property,
            sorter: true,
            showSorterTooltip: false,
            sortIcon: (props) => (
              <IconSort
                width="16"
                height="16"
                order={props.sortOrder as SortOrder}
              />
            ),
            render: (value) => {
              return (
                <Tag color={value ? 'red' : undefined}>
                  {value ? 'Archived' : 'Active'}
                </Tag>
              );
            },
          };
        case 'supplier.name':
          return {
            title: 'Supplier',
            width: 200,
            ellipsis: true,
            key: property,
            dataIndex: property,
            sorter: true,
            showSorterTooltip: false,
            filterDropdown: (props) => (
              <TableFilters
                {...props}
                filters={filters}
                setSearch={setFilterSearch}
                loading={searchLoading}
                isFilterSearch={!!isFilterSearch}
              />
            ),
            filters,
            filterMultiple: true,
            filterIcon: (filtered) => (
              <IconFilter
                width="16"
                height="16"
                color={filtered ? styles.iconFillActive : undefined}
              />
            ),
            sortIcon: (props) => (
              <IconSort
                width="16"
                height="16"
                order={props.sortOrder as SortOrder}
              />
            ),
            render: (value) => {
              return <Cell isValue={!!value}>{value}</Cell>;
            },
          };
        case 'productOfferPrice.price':
          return {
            title: 'List price',
            width: 120,
            align: 'end',
            ellipsis: true,
            key: property,
            dataIndex: property,
            sorter: true,
            showSorterTooltip: false,
            sortIcon: (props) => (
              <IconSort
                width="16"
                height="16"
                order={props.sortOrder as SortOrder}
              />
            ),
            render: (value, record) => {
              if (record.isEditing) {
                return (
                  <Form.Item className={styles.noMargin} name={property}>
                    <InputNumber<number>
                      className={styles.numeric}
                      size="small"
                      min={0.01}
                      controls={false}
                      formatter={(value) => {
                        if (!value && value !== 0) {
                          return '';
                        }
                        if (value <= 0) {
                          return formatNumber(0.01);
                        }
                        return formatNumber(value);
                      }}
                    />
                  </Form.Item>
                );
              }
              return <Cell isValue={!!value}>{formatNumber(value)}</Cell>;
            },
          };

        case 'productOfferPrice.currency':
          return {
            title: 'Currency',
            width: 96,
            ellipsis: true,
            key: property,
            dataIndex: property,
            sorter: true,
            showSorterTooltip: false,
            sortIcon: (props) => (
              <IconSort
                width="16"
                height="16"
                order={props.sortOrder as SortOrder}
              />
            ),
            render: (value, record) => {
              if (record.isEditing) {
                return (
                  <Form.Item className={styles.noMargin} name={property}>
                    <Select size="small" options={currencies} />
                  </Form.Item>
                );
              }
              return (
                <Cell isValue={!!value}>
                  {currencies.find((el) => el.value === value)?.label}
                </Cell>
              );
            },
          };
        case 'quantity':
          return {
            title:withSupplier ? 'Qty' :  'Assigned Qty',
            width: withSupplier ? 70 : 128,
            align: 'end',
            ellipsis: true,
            key: property,
            dataIndex: property,
            sorter: true,
            showSorterTooltip: false,
            sortIcon: (props) => (
              <IconSort
                width="16"
                height="16"
                order={props.sortOrder as SortOrder}
              />
            ),
            render: (value, record) => {
              if (record.isEditing) {
                return (
                  <Form.Item className={styles.noMargin} name={property}>
                    <InputNumber<number>
                      className={styles.numeric}
                      size="small"
                      min={0.01}
                      controls={false}
                      formatter={(value) => {
                        if (!value && value !== 0) {
                          return '';
                        }
                        if (value <= 0) {
                          return formatNumber(0.01);
                        }
                        return formatNumber(value);
                      }}
                    />
                  </Form.Item>
                );
              }
              return <Cell isValue={!!value}>{formatNumber(value)}</Cell>;
            },
          };
        case 'unitOfMeasurement':
          return {
            title: withSupplier ? 'UoM' : 'Assigned UoM',
            width:  withSupplier ? 70 : 134,
            ellipsis: true,
            key: property,
            dataIndex: property,
            sorter: true,
            showSorterTooltip: false,
            sortIcon: (props) => (
              <IconSort
                width="16"
                height="16"
                order={props.sortOrder as SortOrder}
              />
            ),
            render: (value, record) => {
              if (record.isEditing) {
                return (
                  <Form.Item className={styles.noMargin} name={property}>
                    <Select size="small" options={uoms} />
                  </Form.Item>
                );
              }
              return (
                <Cell isValue={!!value}>
                  {uoms.find((el) => el.value === value)?.label}
                </Cell>
              );
            },
          };
        case 'productOfferPrice.startDate':
          return {
            title: 'Price effective',
            width: 130,
            ellipsis: true,
            key: property,
            dataIndex: property,
            sorter: true,
            showSorterTooltip: false,
            sortIcon: (props) => (
              <IconSort
                width="16"
                height="16"
                order={props.sortOrder as SortOrder}
              />
            ),
            render: (value, record) => {
              if (record.isEditing) {
                return (
                  <Form.Item className={styles.noMargin} name={property}>
                    <DatePicker
                      size="small"
                      format={'DD/MM/YYYY'}
                      suffixIcon={
                        <IconDate
                          width="16"
                          height="16"
                          color={styles.iconCalendar}
                        />
                      }
                    />
                  </Form.Item>
                );
              }
              return <Cell isValue={!!value}>{formatDate(value)}</Cell>;
            },
          };
        case 'productOfferPrice.expirationDate':
          return {
            title: 'Price expiration',
            width: 138,
            ellipsis: true,
            key: property,
            dataIndex: property,
            sorter: true,
            showSorterTooltip: false,
            sortIcon: (props) => (
              <IconSort
                width="16"
                height="16"
                order={props.sortOrder as SortOrder}
              />
            ),
            render: (value, record) => {
              if (record.isEditing) {
                return (
                  <Form.Item
                    className={styles.noMargin}
                    name={property}
                    initialValue={value ? dayjs(value) : value}
                  >
                    <DatePicker
                      size="small"
                      format={'DD/MM/YYYY'}
                      suffixIcon={
                        <IconDate
                          width="16"
                          height="16"
                          color={styles.iconCalendar}
                        />
                      }
                    />
                  </Form.Item>
                );
              }
              return (
                <Cell isValue={!!value}>
                  <Typography.Text
                    className={clsx(styles.noMargin, {
                      [styles.expired]: new Date(value) < new Date(),
                    })}
                  >
                    {formatDate(value)}
                  </Typography.Text>
                </Cell>
              );
            },
          };
        case 'createdAt':
          return {
            title: 'Assigned on',
            width: 128,
            ellipsis: true,
            key: property,
            dataIndex: property,
            sorter: true,
            defaultSortOrder: 'descend',
            showSorterTooltip: false,
            sortIcon: (props) => (
              <IconSort
                width="16"
                height="16"
                order={props.sortOrder as SortOrder}
              />
            ),
            render: (value) => {
              return <Cell isValue={!!value}>{formatDate(value)}</Cell>;
            },
          };
        case 'product.materialNumber':
          return {
            title: 'Material number',
            width: 148,
            ellipsis: true,
            key: property,
            dataIndex: property,
            sorter: true,
            showSorterTooltip: false,
            sortIcon: (props) => (
              <IconSort
                width="16"
                height="16"
                order={props.sortOrder as SortOrder}
              />
            ),
            render: (value) => {
              return (
                <Cell isValue={!!value}>
                  <Highlighter value={value} search={search || ''} />
                </Cell>
              );
            },
          };
        case 'product.partNumber':
          return {
            title: 'Part number',
            width: 136,
            ellipsis: true,
            key: property,
            dataIndex: property,
            sorter: true,
            showSorterTooltip: false,
            sortIcon: (props) => (
              <IconSort
                width="16"
                height="16"
                order={props.sortOrder as SortOrder}
              />
            ),
            render: (value) => {
              return (
                <Cell isValue={!!value}>
                  <Highlighter value={value} search={search || ''} />
                </Cell>
              );
            },
          };
        case 'product.barCode':
          return {
            title: 'Barcode',
            width: 136,
            ellipsis: true,
            key: property,
            dataIndex: property,
            sorter: true,
            showSorterTooltip: false,
            sortIcon: (props) => (
              <IconSort
                width="16"
                height="16"
                order={props.sortOrder as SortOrder}
              />
            ),
            render: (value) => {
              return (
                <Cell isValue={!!value}>
                  <Highlighter value={value} search={search || ''} />
                </Cell>
              );
            },
          };
        case 'actions':
          if (actionsHidden) {
            return { hidden: true };
          }
          if (isHistoryAction) {
            return {
              key: property,
              width: 32,
              dataIndex: property,
              fixed: 'right',
              render: () => {
                return (
                  <Tooltip title="Purchase history">
                    <Button
                      size="small"
                      type="text"
                      icon={
                        <IconHistory
                          color={styles.iconFill}
                          height="16"
                          width="16"
                        />
                      }
                    />
                  </Tooltip>
                );
              },
            };
          }
          return {
            key: property,
            width: 60,
            dataIndex: property,
            fixed: 'right',
            render: (value, record, index) => {
              return record.isEditing ? (
                <div className={styles.component}>
                  <Tooltip title="Save">
                    <Button
                      size="small"
                      type="text"
                      onClick={async (e) => {
                        e.stopPropagation();
                        if (
                          !customerId ||
                          !setLoading ||
                          !edit ||
                          !showSuccessMessage ||
                          !showErrorMessage
                        ) {
                          return;
                        }
                        setLoading(true);

                        const data = form?.getFieldsValue();

                        const res = await edit(customerId, record.key, {
                          price: data['productOfferPrice.price'] || 0,
                          currency: data['productOfferPrice.price']
                            ? data['productOfferPrice.currency']
                            : 0,
                          unitOfMeasurementId: data.quantity
                            ? data.unitOfMeasurement
                            : null,
                          quantity: data.quantity || 0,
                          expirationDate:
                            data['productOfferPrice.expirationDate'],
                          startDate: data['productOfferPrice.startDate'],
                        });
                        if (!res.errors) {
                          showSuccessMessage('Changes saved');
                          const broadcast = new BroadcastChannel(BROADCASTNAME);
                          broadcast.postMessage('refresh');
                          broadcast.close();
                        } else {
                          showErrorMessage('Changes not saved');
                        }
                      }}
                      icon={
                        <IconCheck
                          color={styles.iconFillActive}
                          height="16"
                          width="16"
                        />
                      }
                    />
                  </Tooltip>
                  <Tooltip title="Cancel">
                    <Button
                      size="small"
                      type="text"
                      onClick={(e) => {
                        e.stopPropagation();
                        if (!unsetEditRow) {
                          return;
                        }
                        unsetEditRow(index);
                      }}
                      icon={
                        <IconClose
                          color={styles.iconFillActive}
                          height="16"
                          width="16"
                        />
                      }
                    />
                  </Tooltip>
                </div>
              ) : (
                <div
                  className={clsx(styles.component, {
                    [styles.end]: !value.canEdit,
                  })}
                >
                  {value.canEdit && (
                    <Tooltip title="Edit">
                      <Button
                        disabled={isEditing}
                        size="small"
                        type="text"
                        onClick={(e) => {
                          e.stopPropagation();
                          if (!setEditRow) {
                            return;
                          }
                          setEditRow(index, record);
                        }}
                        icon={
                          <IconEdit
                            color={styles.iconFill}
                            height="16"
                            width="16"
                          />
                        }
                      />
                    </Tooltip>
                  )}
                  {value.canDelete && (
                    <Tooltip title="Unassign">
                      <Button
                        size="small"
                        type="text"
                        onClick={(e) => {
                          e.stopPropagation();
                          if (!modalDispatch) {
                            return;
                          }
                          modalDispatch({
                            type: ModalContextActions.SHOW_MODAL,
                            value: {
                              title: 'Unassign product from customer',
                              description: `Product ${record['product.name']} will be removed from the list.`,
                              actionText: 'Unassign',
                              action: ActionType.UNASSIGN_CUSTOMERS_PRODUCT,
                              dataId: customerId,
                              subDataId: record.key,
                              dataName: record['product.name'] || '-',
                            },
                          });
                        }}
                        icon={
                          <IconUnlink
                            color={styles.iconFill}
                            height="16"
                            width="16"
                          />
                        }
                      />
                    </Tooltip>
                  )}
                </div>
              );
            },
          };

        default:
          return { hidden: true };
      }
    }
  );
  return result;
};
