import { Button, Tooltip, Typography } from 'antd';
import clsx from 'clsx';
import { IconAttachment } from 'src/components/icons/attachment/IconAttachment';
import { IconDelete } from 'src/components/icons/delete/IconDelete';
import { IconDownload } from 'src/components/icons/download/IconDownload';
import { IconRestore } from 'src/components/icons/restore/IconRestore';
import { IconSync } from 'src/components/icons/sync/IconSync';
import { API_URL } from 'src/contexts/network-context';
import { IFile } from 'src/types/general';
import { formatDate } from 'src/utils/date-formatter';
import styles from './File.module.scss';

const { Text } = Typography;

type TFileProps = {
  file?: IFile | null;
  readOnly?: boolean;
  reupload?: () => void;
  onDelete?: () => void;
  retry?: () => void;
  isError?: boolean;
};

const File = ({
  readOnly = false,
  onDelete,
  file,
  reupload,
  isError,
  retry,
}: TFileProps) => {
  const canReupload = !!reupload;
  const isAdditionalInfo = readOnly || !!onDelete || canReupload;

  const textClassName = clsx(styles.text, {
    [styles.error]: isError,
  });
  const descriptionClassName = clsx(styles.description, {
    [styles.error]: isError,
  });

  const description = isError
    ? 'File upload failed'
    : file
      ? formatDate(file?.createdAt, 'DD/MM/YY hh:mm:ss')
      : '-';

  const firstTooltip = isError ? 'Reload' : 'Download';

  const FirstActionIcon = isError ? IconSync : IconDownload;

  const download = () => {
    if (!file?.blobName) {
      return;
    }
    window.open(`${API_URL}/epd/download/${file.blobName}`);
  };

  const firstAction = isError ? retry : download;

  const secondTooltip = canReupload ? 'Reload' : 'Download';

  const SecondActionIcon = canReupload ? IconRestore : IconDelete;

  const secondAction = canReupload ? reupload : onDelete;

  return (
    <div
      className={clsx(styles.component, {
        [styles.between]: isAdditionalInfo,
      })}
    >
      <div
        className={clsx(styles.body, {
          [styles.wrapped]: isError || readOnly || canReupload,
        })}
      >
        {isAdditionalInfo ? (
          <>
            <IconAttachment width="16" height="16" className={styles.icon} />
            <Text className={textClassName}>{file?.fileName}</Text>
          </>
        ) : (
          <Button
            type="text"
            size="small"
            className={styles.body}
            icon={
              <IconAttachment width="16" height="16" className={styles.icon} />
            }
            onClick={(e) => {
              e.stopPropagation();
              download();
            }}
          >
            <Text className={textClassName}>{file?.fileName}</Text>
          </Button>
        )}
      </div>
      <div className={styles.flex}>
        {(isError || readOnly || canReupload) && (
          <div className={styles.descriptionContainer}>
            <Text className={descriptionClassName}>{description}</Text>
            <Tooltip title={firstTooltip}>
              <Button
                size="small"
                type="text"
                icon={
                  <FirstActionIcon
                    width="16"
                    height="16"
                    className={styles.icon}
                  />
                }
                onClick={firstAction}
              />
            </Tooltip>
          </div>
        )}
        {(!!onDelete || (canReupload && !readOnly)) && (
          <Tooltip title={secondTooltip}>
            <Button
              size="small"
              type="text"
              className={styles.delete}
              icon={
                <SecondActionIcon
                  width="16"
                  height="16"
                  className={styles.icon}
                />
              }
              onClick={secondAction}
            />
          </Tooltip>
        )}
      </div>
    </div>
  );
};

export default File;
