import { InboxOutlined } from '@ant-design/icons';
import {
  Button,
  Form,
  Input,
  Select,
  Typography,
  UploadFile,
  UploadProps,
  message,
  theme,
} from 'antd';
import Dragger from 'antd/es/upload/Dragger';
import { useEffect, useState } from 'react';
import FormItem from 'src/components/form-item/FormItem';
import { LogoOnly } from 'src/components/logo/logo-only/LogoOnly';
import File from 'src/components/table/file/File';
import { issuePriorityOptions, issueTypeOptions } from 'src/constants/support';
import { REQUIRED } from 'src/constants/validation';
import styles from './Support.module.scss';
import useMessage from 'src/hooks/use-message';
import { IconCheckOutlined } from 'src/components/icons/check-outlined/IconCheckOutlined';

const Support = () => {
  const [form] = Form.useForm();
  const attachments = Form.useWatch('attachments', form);
  const [file, setFile] = useState<UploadFile | null>(null);
  const [disabled, setDisabled] = useState(false);

  const { showSuccessMessage } = useMessage();
  const [messageApi, contextHolder] = message.useMessage();
  const {
    token: { colorSuccess },
  } = theme.useToken();

  useEffect(() => {
    form.setFieldValue('priority', '1');
  }, []);
  const draggerProps: UploadProps = {
    style: {
      ...(file
        ? {
            display: 'none',
          }
        : {
            maxHeight: '146px',
          }),
    },
    multiple: false,
    disabled,
    maxCount: 1,
    onRemove: () => {
      setFile(null);
    },
    itemRender: (_, file, __, actions) => {
      return (
        <File
          file={{
            fileName: file.name || '',
            blobName: attachments?.blobName || file.name || '',
            createdAt: new Date(),
          }}
          onDelete={disabled ? undefined : actions.remove}
        />
      );
    },
    beforeUpload: (file) => {
      setFile(file);

      return false;
    },
    fileList: file ? [file] : [],
  };

  showSuccessMessage('Support request sent');

  return (
    <div className={styles.container}>
      <div className={styles.formContainer}>
        <div className={styles.content}>
          <Typography.Title className={styles.title} level={5}>
            Need help? Contact our support team bee
          </Typography.Title>
          <Typography.Text className={styles.description}>
            Please fill out the form below. Our support team will review your
            request and get back to you as soon as possible.
          </Typography.Text>
          <Form
            form={form}
            layout="vertical"
            requiredMark={'optional'}
            className={styles.form}
          >
            <FormItem
              name="type"
              label="Issue type"
              rules={[{ required: true, message: REQUIRED }]}
            >
              <Select disabled={disabled} options={issueTypeOptions} />
            </FormItem>
            <FormItem
              name="description"
              label="Description"
              rules={[{ required: true, message: REQUIRED }]}
            >
              <Input.TextArea disabled={disabled} />
            </FormItem>
            <FormItem name="attachments" label="Attachments">
              <Dragger {...draggerProps}>
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">
                  Click or drag file to this area to upload
                </p>
                <p className="ant-upload-hint">
                  Support for a single upload only.
                </p>
              </Dragger>
            </FormItem>
            <FormItem
              name="priority"
              label="Priority"
              rules={[{ required: true, message: REQUIRED }]}
            >
              <Select
                disabled={disabled}
                className={styles.priority}
                options={issuePriorityOptions}
              />
            </FormItem>
          </Form>
          {!disabled && (
            <div className={styles.buttonContainer}>
              <Button>Cancel</Button>
              <Button
                type="primary"
                onClick={() =>
                  form
                    .validateFields()
                    .then(() => {
                      setDisabled(true);
                      messageApi.success({
                        content: 'Support request sent',
                        icon: (
                          <IconCheckOutlined
                            className={styles.marginRight}
                            height="16"
                            width="16"
                            fill={colorSuccess}
                          />
                        ),
                      });
                    })
                    .catch(() => {})
                }
              >
                Send
              </Button>
            </div>
          )}
        </div>
      <LogoOnly height="329" width="316" className={styles.illustration1} />
      <LogoOnly height="615" width="640" className={styles.illustration2} />
      <LogoOnly height="403" width="420" className={styles.illustration3} />
      </div>
      {contextHolder}
    </div>
  );
};

export default Support;
