import { TableProps } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { useModalContext } from 'src/contexts/modal-context';
import { ITableParams } from 'src/types/generic';
import {
  TMappedCustomerRecord,
  getCompanyColumnSetting,
  getMappedCustomerData,
} from 'src/utils/map-companies-table';
import useCompanies from './use-companies';
import useDebouncer from './use-debouncer';

const TABLE_WIDTH = 1600;

const useCustomersList = () => {
  const { getCustomers } = useCompanies();

  const { modalContextDispatch } = useModalContext();

  const [data, setData] = useState<TMappedCustomerRecord[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [selectedId, setSelectedId] = useState<number | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [search, setSearch] = useState<string | null>(null);
  const [debouncedSearch, setDebouncedSearch] = useState<string>('');
  const [tableParams, setTableParams] = useState<ITableParams>({
    pagination: {
      current: 1,
      pageSize: 10,
    },
    sortField: 'gln',
    sortOrder: 'ascend',
  });

  const columns = useMemo(() => {
    const res = getCompanyColumnSetting<TMappedCustomerRecord>({
      modalDispatch: modalContextDispatch,
      search: debouncedSearch,
      isCustomer: true,
    });
    return res;
  }, [tableParams, debouncedSearch]);

  // Note: debounces search value
  useDebouncer(setDebouncedSearch, search || '');

  const fetchCustomers = async () => {
    setLoading(true);
    const result = await getCustomers({
      ...tableParams,
      search: debouncedSearch,
      includeDeleted: true,
    });

    if (result.result?.data.data) {
      setData(getMappedCustomerData(result.result.data.data));
      setTotal(result.result.data.totalCount);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchCustomers();
  }, [tableParams]);

  useEffect(() => {
    if (
      debouncedSearch.length >= 3 ||
      (!debouncedSearch.length && search !== null)
    ) {
      fetchCustomers();
    }
  }, [debouncedSearch]);

  const handleTableChange: TableProps<any>['onChange'] = (
    pagination,
    filters,
    sorter
  ) => {
    setTableParams({
      pagination,
      filters,
      sortOrder: Array.isArray(sorter) ? undefined : sorter.order,
      sortField: Array.isArray(sorter)
        ? undefined
        : sorter.order
          ? sorter.field
          : undefined,
    });

    // `dataSource` is useless since `pageSize` changed
    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setData([]);
    }
  };

  return {
    handleTableChange,
    setSearch,
    loading,
    columns,
    width: TABLE_WIDTH,
    data,
    debouncedSearch,
    selectedId,
    setSelectedId,
    total,
    search,
  };
};

export default useCustomersList;
