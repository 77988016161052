import { TableProps } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { BROADCASTNAME } from 'src/constants/broadcast';
import { useModalContext } from 'src/contexts/modal-context';
import { ITableParams } from 'src/types/generic';
import {
  TMappedSupplierRecord,
  getCompanyColumnSetting,
  getMappedSupplierData,
} from 'src/utils/map-companies-table';
import useCompanies from './use-companies';
import useDebouncer from './use-debouncer';
import { useUserContext } from 'src/contexts/user-context';

const TABLE_WIDTH = 1680;

const useSuppliersList = () => {
  const { getSuppliers } = useCompanies();

  const { modalContextDispatch } = useModalContext();
  const {
    userContextState: { isAdmin, isAdvancedUser },
  } = useUserContext();

  const basicUser = !isAdmin && !isAdvancedUser;

  const [data, setData] = useState<TMappedSupplierRecord[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [open, setOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [search, setSearch] = useState<string | null>(null);
  const [debouncedSearch, setDebouncedSearch] = useState<string>('');
  const [tableParams, setTableParams] = useState<ITableParams>({
    pagination: {
      current: 1,
      pageSize: 10,
    },
    sortField: 'gln',
    sortOrder: 'ascend',
  });

  const columns = useMemo(() => {
    const res = getCompanyColumnSetting<TMappedSupplierRecord>({
      modalDispatch: modalContextDispatch,
      search: debouncedSearch,
      isSupplier: true,
      noAction: basicUser || !total,
    });
    return res;
  }, [tableParams, debouncedSearch, isAdmin, total]);

  // Note: debounces search value
  useDebouncer(setDebouncedSearch, search || '');

  const fetchSuppliers = async () => {
    setLoading(true);
    const result = await getSuppliers({
      ...tableParams,
      search: debouncedSearch.length >= 3 ? debouncedSearch : '',
    });
    if (result.result?.data.data) {
      setData(getMappedSupplierData(result.result.data.data));
      setTotal(result.result.data.totalCount);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchSuppliers();
    const broadcast = new BroadcastChannel(BROADCASTNAME);
    broadcast.onmessage = fetchSuppliers;
    return () => {
      broadcast.close();
    };
  }, [tableParams]);

  useEffect(() => {
    if (
      debouncedSearch.length >= 3 ||
      (!debouncedSearch.length && search !== null)
    ) {
      fetchSuppliers();
    }
  }, [debouncedSearch]);

  const handleTableChange: TableProps<any>['onChange'] = (
    pagination,
    filters,
    sorter
  ) => {
    setTableParams({
      pagination,
      filters,
      sortOrder: Array.isArray(sorter) ? undefined : sorter.order,
      sortField: Array.isArray(sorter)
        ? undefined
        : sorter.order
          ? sorter.field
          : undefined,
    });

    // `dataSource` is useless since `pageSize` changed
    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setData([]);
    }
  };

  return {
    handleTableChange,
    setSearch,
    loading,
    columns,
    width: TABLE_WIDTH,
    data,
    total,
    debouncedSearch,
    open,
    setOpen,
    search,
  };
};

export default useSuppliersList;
