import { Tabs } from 'antd';
import { IconAdd } from 'src/components/icons/add/IconAdd';
import { IconDownload } from 'src/components/icons/download/IconDownload';
import { IconUpload } from 'src/components/icons/upload/IconUpload';
import ProductsDrawer from 'src/components/products-drawer/ProductsDrawer';
import Table from 'src/components/table/Table';
import TableHeader from 'src/components/table/header/TableHeader';
import { productTabs } from 'src/constants/products';
import {
  NO_PRODUCTS_ADDED,
  NO_PRODUCTS_ASSIGNED,
  NO_RESULTS,
} from 'src/constants/validation';
import useProductsList from 'src/hooks/use-products-list';
import { TMappedProductOfferRecord } from 'src/utils/map-assigned-product-table';
import { TMappedProductListRecord } from 'src/utils/map-product-table';
import styles from './ProductList.module.scss';

const ProductList = () => {
  const {
    setSearch,
    columns,
    handleTableChange,
    loading,
    width,
    data,
    total,
    debouncedSearch,
    openAdd,
    openRead,
    productId,
    mode,
    open,
    closeDrawer,
    search,
    defaultTab,
    handleTabChange,
    isSuppliers,
    suppliersColumns,
    supplierData,
    suppliersTotal,
    suppliersLoading,
  } = useProductsList();

  return (
    <div>
      <Tabs
        activeKey={defaultTab}
        items={productTabs}
        onChange={handleTabChange}
      />
      <TableHeader
        searchPlaceholder={'Product name, number, code'}
        setSearch={setSearch}
        primaryActionTitle={'Import Products'}
        primaryActionIcon={<IconUpload width="16" height="16" />}
        primaryAction={() => {}}
        search={search}
        secondaryActionTitle={'Add Product'}
        secondaryActionIcon={<IconAdd width="16" height="16" />}
        secondaryAction={openAdd}
        noActions={isSuppliers}
        customActionTitle={'Export to .xlsx'}
        customActionIcon={<IconDownload width="16" height="16" />}
        cusotmAction={() => {}}
      />
      {isSuppliers ? (
        <Table<TMappedProductOfferRecord>
          emptyTextDescription={
            debouncedSearch ? NO_RESULTS : NO_PRODUCTS_ASSIGNED
          }
          columns={suppliersColumns}
          dataSource={supplierData}
          onChange={handleTableChange}
          rowClassName={() => {
            return styles.interactive;
          }}
          onRow={(record) => ({
            onClick: () => {
              openRead(record);
            },
          })}
          loading={suppliersLoading}
          width={width}
          height={'calc(100vh - 326px)'}
          total={suppliersTotal}
        />
      ) : (
        <Table<TMappedProductListRecord>
          emptyTextDescription={
            debouncedSearch ? NO_RESULTS : NO_PRODUCTS_ADDED
          }
          columns={columns}
          dataSource={data}
          onChange={handleTableChange}
          rowClassName={() => {
            return styles.interactive;
          }}
          onRow={(record) => ({
            onClick: () => {
              openRead(record);
            },
          })}
          loading={loading}
          width={width}
          height={'calc(100vh - 326px)'}
          total={total}
        />
      )}
      <ProductsDrawer
        open={open}
        productId={productId}
        onClose={closeDrawer}
        mode={mode}
      />
    </div>
  );
};

export default ProductList;
