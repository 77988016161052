import { Outlet, RouterProvider, createBrowserRouter } from 'react-router-dom';
import AuthWrapper from 'src/components/auth-wrapper/AuthWrapper';
import GeneralLayout from 'src/components/layout/Layout';
import { MessageContextProvider } from 'src/contexts/message-context';
import { ModalContextProvider } from 'src/contexts/modal-context';
import { NetworkContextProvider } from 'src/contexts/network-context';
import { UserContextProvider } from 'src/contexts/user-context';
import CustomerDetails from 'src/pages/customer-list/details/CustomerDetails';
import CustomerList from 'src/pages/customer-list/CustomerList';
import ProductList from 'src/pages/product-list/ProductList';
import SignIn from 'src/pages/sign-in/SignIn';
import SupplierList from 'src/pages/supplier-list/SupplierList';
import 'src/styles/global.scss';
import { ProtectedRoutes, RouteTitle, Routes } from 'src/types/routes';
import { NavigationContextProvider } from 'src/contexts/navigation-context';
import ProductDetails from 'src/pages/product-list/details/ProductDetails';
import SupplierDetails from 'src/pages/supplier-list/details/SupplierDetails';
import SupplierProductPurchaseHistory from 'src/pages/supplier-list/purchase-history/SupplierProductPurchaseHistory';
import Dashboard from 'src/pages/dashboard/Dashboard';
import HiveMap from 'src/pages/hive-map/HiveMap';
import Support from 'src/pages/support/Support';

const router = createBrowserRouter([
  {
    element: (
      <UserContextProvider>
        <NetworkContextProvider>
          <AuthWrapper>
            <MessageContextProvider>
              <ModalContextProvider>
                <NavigationContextProvider>
                  <Outlet />
                </NavigationContextProvider>
              </ModalContextProvider>
            </MessageContextProvider>
          </AuthWrapper>
        </NetworkContextProvider>
      </UserContextProvider>
    ),
    children: [
      {
        element: (
          <GeneralLayout>
            <Outlet />
          </GeneralLayout>
        ),
        children: [
          {
            element: <SupplierList />,
            path: ProtectedRoutes.SUPPLIERS,
          },
          {
            element: <SupplierDetails />,
            path: `${ProtectedRoutes.SUPPLIERS}/:supplier_id`,
          },
          {
            element: <SupplierProductPurchaseHistory />,
            path: `${ProtectedRoutes.SUPPLIERS}/:supplier_id/:product_offer_id`,
          },
          {
            element: <CustomerList />,
            path: ProtectedRoutes.CUSTOMERS,
          },
          {
            element: <CustomerDetails />,
            path: `${ProtectedRoutes.CUSTOMERS}/:customer_id`,
          },
          {
            element: <ProductList />,
            path: ProtectedRoutes.PRODUCTS,
          },
          {
            element: <ProductDetails />,
            path: `${ProtectedRoutes.PRODUCTS}/:product_id`,
          },
          {
            element: <span>{RouteTitle[ProtectedRoutes.INSIGHTS]}</span>,
            path: ProtectedRoutes.INSIGHTS,
          },
          {
            element: <Support />,
            path: ProtectedRoutes.NEWS_AND_SUPPORT,
          },
        ],
      },
      {
        element: (
          <GeneralLayout withContent={false}>
            <Outlet />
          </GeneralLayout>
        ),
        children: [
          {
            element: <Dashboard />,
            path: ProtectedRoutes.DASHBOARD,
          },
          {
            element: <HiveMap />,
            path: ProtectedRoutes.HIVE,
          },
        ],
      },
      { path: Routes.SIGNIN, element: <SignIn /> },
      { path: '*', element: <p>404 not found</p> },
    ],
  },
]);

const AppRouterProvider = () => {
  return (
    <div id="app">
      <RouterProvider router={router} />
    </div>
  );
};

export default AppRouterProvider;
