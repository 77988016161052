import { TableProps } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { BROADCASTNAME } from 'src/constants/broadcast';
import { useMessageContext } from 'src/contexts/message-context';
import { useModalContext } from 'src/contexts/modal-context';
import { useNavigationContext } from 'src/contexts/navigation-context';
import { ITableParams } from 'src/types/generic';
import {
  getAssignedProductsColumnSetting,
  getMappedProductOffersData,
} from 'src/utils/map-assigned-product-table';
import useCompanies from './use-companies';
import useProductOfferTableForm from './use-product-offer-table-form';

const TABLE_WIDTH = 1480;

const useCustomerDetails = () => {
  const { customer_id } = useParams();

  const customerId = customer_id || '';

  const { getCustomer, getCustomerProducts, editAssignedProduct } =
    useCompanies();

  const { showErrorMessage, showSuccessMessage } = useMessageContext();

  const { modalContextDispatch } = useModalContext();
  const { setTitle } = useNavigationContext();

  const {
    form,
    data,
    setEditRow,
    unsetEditRow,
    isEditing,
    uoms,
    currencies,
    getAllMeasurements,
    measurementsLoading,
    setData,
  } = useProductOfferTableForm();

  const [customerName, setCustomerName] = useState<string | null>('');
  const [customerStatus, setCustomerStatus] = useState<boolean>(false);
  const [total, setTotal] = useState<number>(0);
  const [open, setOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [tableParams, setTableParams] = useState<ITableParams>({
    pagination: {
      current: 1,
      pageSize: 10,
    },
    sortField: 'createdAt',
    sortOrder: 'descend',
  });

  const columns = useMemo(() => {
    const res = getAssignedProductsColumnSetting({
      modalDispatch: modalContextDispatch,
      setEditRow,
      setLoading,
      unsetEditRow,
      edit: editAssignedProduct,
      customerId,
      uoms,
      currencies,
      actionsHidden: customerStatus,
      isEditing,
      form,
      showSuccessMessage,
      showErrorMessage,
    });
    return res;
  }, [tableParams, uoms, currencies, customerStatus, isEditing]);

  const fetchProducts = async () => {
    setLoading(true);
    const productsResult = await getCustomerProducts(customerId, tableParams);
    const productsResponse = productsResult.result?.data;

    if (productsResponse) {
      setData(getMappedProductOffersData(productsResponse.data));
      setTotal(productsResponse.totalCount);
    }
    const customerResult = await getCustomer(customerId);
    const customerResponse = customerResult.result?.data;

    if (customerResponse) {
      setCustomerName(customerResponse.name);
      setTitle([customerResponse.name]);
      setCustomerStatus(customerResponse.isDeleted);
    }
    setLoading(false);
  };

  useEffect(() => {
    getAllMeasurements();
  }, []);

  useEffect(() => {
    fetchProducts();
    const broadcast = new BroadcastChannel(BROADCASTNAME);
    broadcast.onmessage = fetchProducts;
    return () => {
      broadcast.close();
    };
  }, [tableParams]);

  const handleTableChange: TableProps<any>['onChange'] = (
    pagination,
    filters,
    sorter
  ) => {
    setTableParams({
      pagination,
      filters,
      sortOrder: Array.isArray(sorter) ? undefined : sorter.order,
      sortField: Array.isArray(sorter)
        ? undefined
        : sorter.order
          ? sorter.field
          : undefined,
    });

    // `dataSource` is useless since `pageSize` changed
    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setData([]);
    }
  };

  return {
    handleTableChange,
    loading: loading || measurementsLoading,
    columns,
    width: TABLE_WIDTH,
    data,
    total,
    customerName,
    form,
    open,
    customerId,
    setOpen,
    customerStatus,
  };
};

export default useCustomerDetails;
