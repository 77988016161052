export interface IFile {
  blobName: string | null;
  fileName: string | null;
  createdAt: Date;
}

export interface IInvoice extends Omit<IFile, 'createdAt'> {
  purchaseOrderNumber: string | null;
  invoiceDate: Date;
}
export interface IProduct {
  id: string;
  name: string | null;
  materialNumber: string | null;
  partNumber: string | null;
  barCode: string | null;
  externalEpdUrl: string | null;
  unitOfMeasurement: string | null;
  unitOfMeasurementId: string | null;
  rrpPrice: number | null;
  quantity: number | null;
  currency: number;
  canEdit: boolean;
  canDelete: boolean;
  epdFile: IFile | null;
  createdAt: Date;
  createdByName: string | null;
  modifiedAt: Date;
  modifiedByName: string | null;
}

export interface IAction {
  resource: string;
  action: string;
}

export enum Permission {
  ADMIN = 'Admin',
  ADVANCED_USER = 'AdvancedUser',
  BASIC_USER = 'BasicUser',
}

export interface IPermisson {
  name: Permission;
  permissions: IAction[];
}

export interface ICompany {
  id: string;
  name: string | null;
  gln: string | null;
  industry: string | null;
  status: number;
  address: string | null;
  phoneNumber: string | null;
  primaryContact: string | null;
  primaryEmail: string | null;
  secondaryContact: string | null;
  secondaryEmail: string | null;
  isConnected: boolean;
}

export interface ISupplier extends Omit<ICompany, 'isConnected' | 'status'> {
  connectionId: string;
  supplierPersonalId: string | null;
}
export interface ICustomer extends Omit<ISupplier, 'supplierPersonalId'> {
  isDeleted: boolean;
}

export interface IMappedProductOffer {
  id: string;
  'product.name'?: string | null;
  'supplier.name'?: string | null;
  'customer.gln'?: string | null;
  'customer.name'?: string | null;
  'customer.industry'?: string | null;
  'productOfferPrice.price': number | null;
  'productOfferPrice.currency': number;
  quantity: number | null;
  unitOfMeasurement: string | null;
  'productOfferPrice.startDate': Date | null;
  'productOfferPrice.expirationDate': Date | null;
  createdAt: Date;
  'product.materialNumber'?: string | null;
  'product.partNumber'?: string | null;
  'product.barCode'?: string | null;
  canEdit: boolean;
  canDelete: boolean;
  'product.isDeleted'?: boolean;
}

export interface IMeasurement {
  id: string;
  shortName: string | null;
  name: string | null;
}

export interface ICurrency {
  value: number;
  name: string | null;
}

export interface IProductOffer {
  id: string;
  product: Pick<
    IProduct,
    | 'id'
    | 'name'
    | 'materialNumber'
    | 'partNumber'
    | 'barCode'
    | 'epdFile'
    | 'externalEpdUrl'
  > & {
    isDeleted: boolean;
  };
  customer: Pick<ICustomer, 'id' | 'name' | 'gln' | 'industry'>;
  supplier: Pick<ISupplier, 'id' | 'name' | 'gln' | 'industry'>;
  productOfferPrice: {
    price: number;
    currency: number;
    startDate: Date | null;
    expirationDate: Date | null;
  };
  unitOfMeasurement: string | null;
  unitOfMeasurementId: string | null;
  quantity: number;
  createdAt: Date;
  modifiedByName: string | null;
  canEdit: boolean;
  canDelete: boolean;
}

export interface IProductOfferDetails
  extends Omit<IProductOffer, 'company' | 'canEdit' | 'canDelete'> {
  epdFile: IFile | null;
  externalEpdUrl: string | null;
}

export interface IPurchaseHistory {
  id: string;
  quantity: number;
  currency: number;
  unitOfMeasurementId: string | null;
  unitOfMeasurement: string | null;
  totalAmount: number;
  invoice: IInvoice | null;
  productOffer: {
    id: string;
    price: number;
    currency: number;
  };
  project: {
    id: string;
    personalId: string | null;
    name: string | null;
  };
}

export interface IMappedPurchaseHistory {
  'invoice.purchaseOrderNumber': string | null;
  'invoice.fileName': IInvoice | null;
  'invoice.invoiceDate': Date | null;
  quantity: number;
  unitOfMeasurement: string | null;
  totalAmount: number;
  currency: number;
  'productOffer.price': number;
  'productOffer.currency': number;
  'project.personalId': string | null;
  'project.name': string | null;
}

export interface ICustomerMetric {
  total: number;
  coupled: number;
  decoupled: number;
  pending: number;
  supplierId: string;
}

export interface ISupplierMetric {
  total: number;
  coupled: number;
  decoupled: number;
  pending: number;
  tenantId: string;
}

export interface IDashboardMetrics {
  suppliersStatus: ISupplierMetric;
  customersStatus: ICustomerMetric;
  tenantProductsStatus: {
    total: number;
    active: number;
    dataQualityIssues: number;
    tenantId: string;
  };
  epdFiles: {
    epdTotal: number;
    productsWithEpd: number;
    productsWithoutEpd: number;
    tenantId: string;
  };
  tenantProductsArchived: {
    archived: number;
    tenantId: string;
  };
  tenantProductsPriceExpiration: {
    nonExpired: number;
    expiringIn7Days: number;
    expired: number;
    tenantId: string;
  };
  tenantTopSellingProducts: {
    sellingCount: number;
    productName: string;
    productId: string;
    tenantId: string;
  }[];
  supplierProductsStatus: {
    total: number;
    active: number;
    archived: number;
    customerCompanyId: string;
  };
  supplierProductsPriceExpiration: {
    nonExpired: number;
    expiringIn7Days: number;
    expired: number;
    customerCompanyId: string;
  };
  supplierTopPurchasedProducts: {
    purchasedCount: number;
    productName: string;
    productId: string;
    supplierCompanyId: string;
  }[];
  favouriteReports: {
    name: string;
    lastCreated: Date;
    tenantId: string;
  }[];
}
