import { IconInventory } from 'src/components/icons/inventory/IconInventory';
import ObjectHeader from 'src/components/object-header/ObjectHeader';
import ProductInfo from 'src/components/product-info/ProductInfo';
import Table from 'src/components/table/Table';
import TableHeader from 'src/components/table/header/TableHeader';
import { NO_PRODUCTS_PURCHASED } from 'src/constants/validation';
import useSupplierProductPurchaseHistory from 'src/hooks/use-supplier-product-purchase-history';
import { TMappedPurchaseHistoryRecord } from 'src/utils/map-purchase-history-table';
import styles from './SupplierProductPurchaseHistory.module.scss';

const SupplierProductPurchaseHistory = () => {
  const {
    productOffer,
    columns,
    data,
    handleTableChange,
    loading,
    width,
    total,
    currencies,
  } = useSupplierProductPurchaseHistory();

  return (
    <>
      <ObjectHeader
        Icon={<IconInventory />}
        title={productOffer?.product.name || '-'}
        status={productOffer?.product.isDeleted ? 'Archived' : 'Active'}
        statusColor={productOffer?.product.isDeleted ? 'red' : undefined}
        Details={
          <ProductInfo
            currencies={currencies}
            barCode={productOffer?.product.barCode}
            materialNumber={productOffer?.product.materialNumber}
            partNumber={productOffer?.product.partNumber}
            epdFile={productOffer?.product.epdFile}
            externalEpdUrl={productOffer?.product.externalEpdUrl}
            rrpPrice={productOffer?.productOfferPrice.price}
            currency={productOffer?.productOfferPrice.currency}
            quantity={productOffer?.quantity}
            unitOfMeasurement={productOffer?.unitOfMeasurement}
            supplier={productOffer?.supplier.name}
          />
        }
        noActions
      />
      <div className={styles.container}>
        <TableHeader
          title="Purchase history"
          bold={false}
          isSearch={false}
          onlyPrimaryAction
          noActions
        />
        <Table<TMappedPurchaseHistoryRecord>
          emptyTextDescription={NO_PRODUCTS_PURCHASED}
          columns={columns}
          dataSource={data}
          onChange={handleTableChange}
          loading={loading}
          width={width}
          height={'calc(100vh - 434px)'}
          total={total}
        />
      </div>
    </>
  );
};

export default SupplierProductPurchaseHistory;
