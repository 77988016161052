import { ITableParams } from 'src/types/generic';

export const productTabs = [
  {
    key: '1',
    label: 'My company products',
  },
  {
    key: '2',
    label: "My suppliers' products",
  },
];

export const initialProductTableParams: ITableParams = {
  pagination: {
    current: 1,
    pageSize: 10,
  },
  sortField: 'createdAt',
  sortOrder: 'descend',
};
