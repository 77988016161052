import { Form } from 'antd';
import dayjs from 'dayjs';
import { useEffect, useMemo, useState } from 'react';
import { TMappedProductOfferRecord } from 'src/utils/map-assigned-product-table';
import useMeasurements from './use-measurements';

const useProductOfferTableForm = () => {
  const [form] = Form.useForm<TMappedProductOfferRecord>();
  const [data, setData] = useState<TMappedProductOfferRecord[]>([]);

  const {
    getAllMeasurements,
    uoms,
    currencies,
    loading: measurementsLoading,
  } = useMeasurements();

  const priceEffective = Form.useWatch<dayjs.Dayjs>(
    'productOfferPrice.startDate',
    form
  );
  const priceExpiration = Form.useWatch<dayjs.Dayjs>(
    'productOfferPrice.expirationDate',
    form
  );

  useEffect(() => {
    const priceEffectiveDate = priceEffective?.startOf('day').toDate();
    const priceExpirationDate = priceExpiration?.startOf('day').toDate();

    if (priceEffectiveDate >= priceExpirationDate) {
      form.setFieldValue(
        'productOfferPrice.expirationDate',
        priceEffective.add(1, 'day')
      );
    }
  }, [priceEffective, priceExpiration]);

  const setEditRow = (index: number, record: TMappedProductOfferRecord) => {
    const initialValue: TMappedProductOfferRecord = {
      ...record,
      'productOfferPrice.currency':
        record['productOfferPrice.currency'] ||
        (currencies.find((el) => el.label === 'AUD')?.value as number),
      unitOfMeasurement:
        record.unitOfMeasurement ||
        (uoms.find((el) => el.label === 'pcs')?.value as string),
      quantity: record.quantity || null,
      'productOfferPrice.price': record['productOfferPrice.price'] || null,
      'productOfferPrice.startDate': record['productOfferPrice.startDate']
        ? (dayjs(record['productOfferPrice.startDate']) as any)
        : null,
      'productOfferPrice.expirationDate': record[
        'productOfferPrice.expirationDate'
      ]
        ? (dayjs(record['productOfferPrice.expirationDate']) as any)
        : null,
    };
    form.setFieldsValue(initialValue);

    setData((data) => {
      data[index].isEditing = true;
      return [...data];
    });
  };

  const isEditing = useMemo(() => data.some((el) => el.isEditing), [data]);

  const unsetEditRow = (index: number) => {
    form.resetFields(undefined);

    setData((data) => {
      data[index].isEditing = false;
      return [...data];
    });
  };

  return {
    form,
    data,
    setEditRow,
    unsetEditRow,
    isEditing,
    measurementsLoading,
    getAllMeasurements,
    uoms,
    currencies,
    setData,
  };
};

export default useProductOfferTableForm;
