import { Form } from 'antd';
import AssignProductsModal from 'src/components/assign-modal/assign-product-modal/AssignProductsModal';
import { IconAdd } from 'src/components/icons/add/IconAdd';
import { IconDownload } from 'src/components/icons/download/IconDownload';
import { IconHub } from 'src/components/icons/hub/IconHub';
import { IconUpload } from 'src/components/icons/upload/IconUpload';
import ObjectHeader from 'src/components/object-header/ObjectHeader';
import Table from 'src/components/table/Table';
import TableHeader from 'src/components/table/header/TableHeader';
import { NO_PRODUCTS_PURCHASED } from 'src/constants/validation';
import useCustomerDetails from 'src/hooks/use-customer-details';
import { TMappedProductOfferRecord } from 'src/utils/map-assigned-product-table';
import styles from './CustomerDetails.module.scss';

const CustomerDetails = () => {
  const {
    customerName,
    customerId,
    columns,
    data,
    handleTableChange,
    loading,
    width,
    total,
    form,
    open,
    setOpen,
    customerStatus,
  } = useCustomerDetails();

  return (
    <>
      <ObjectHeader
        Icon={<IconHub />}
        title={customerName || '-'}
        status={customerStatus ? 'Decoupled' : 'Coupled'}
        statusColor={customerStatus ? 'orange' : undefined}
        noActions
      />
      <div className={styles.container}>
        <TableHeader
          title="Assigned products"
          bold={false}
          primaryAction={() => {
            setOpen(true);
          }}
          primaryActionTitle="Assign products"
          primaryActionIcon={<IconAdd width="16" height="16" />}
          secondaryAction={() => {}}
          secondaryActionTitle="Import products"
          secondaryActionIcon={<IconUpload width="16" height="16" />}
          isSearch={false}
          noActions={customerStatus || loading}
          customActionTitle={'Export to .xlsx'}
          customActionIcon={<IconDownload width="16" height="16" />}
          cusotmAction={() => {}}
        />
        <Form form={form} layout="vertical">
          <Table<TMappedProductOfferRecord>
            emptyTextDescription={NO_PRODUCTS_PURCHASED}
            columns={columns}
            dataSource={data}
            onChange={handleTableChange}
            loading={loading}
            width={width}
            height={'calc(100vh - 328px)'}
            total={total}
          />
        </Form>
      </div>
      <AssignProductsModal
        customerId={customerId}
        visible={open}
        onClose={() => {
          setOpen(false);
        }}
      />
    </>
  );
};

export default CustomerDetails;
