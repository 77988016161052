import { TableProps } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useNavigationContext } from 'src/contexts/navigation-context';
import { IProductOfferDetails } from 'src/types/general';
import { ITableParams } from 'src/types/generic';
import {
  TMappedPurchaseHistoryRecord,
  getMappedPurchaseHistoryData,
  getPurchaseHistoryColumnSetting,
} from 'src/utils/map-purchase-history-table';
import useCompanies from './use-companies';
import useMeasurements from './use-measurements';

const TABLE_WIDTH = 1480;

const useSupplierProductPurchaseHistory = () => {
  const { supplier_id, product_offer_id } = useParams();

  const supplierId = supplier_id || '';
  const productOfferId = product_offer_id || '';

  const { getSupplierProduct, getSupplierProductPurchaseHistory } =
    useCompanies();

  const { setTitle } = useNavigationContext();

  const {
    getAllMeasurements,
    uoms,
    currencies,
    loading: measurementsLoading,
  } = useMeasurements();

  const [productOffer, setProductOffer] = useState<IProductOfferDetails | null>(
    null
  );
  const [data, setData] = useState<TMappedPurchaseHistoryRecord[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [tableParams, setTableParams] = useState<ITableParams>({
    pagination: {
      current: 1,
      pageSize: 10,
    },
    sortField: 'invoice.invoiceDate',
    sortOrder: 'descend',
  });

  const columns = useMemo(() => {
    const res = getPurchaseHistoryColumnSetting({
      uoms,
      currencies,
    });
    return res;
  }, [tableParams, uoms, currencies]);

  const fetchProducts = async () => {
    setLoading(true);
    const productsResult = await getSupplierProductPurchaseHistory(
      supplierId,
      productOfferId,
      tableParams
    );
    const productsResponse = productsResult.result?.data;

    if (productsResponse) {
      setData(getMappedPurchaseHistoryData(productsResponse.data));
      setTotal(productsResponse.totalCount);
    }
    const productResult = await getSupplierProduct(supplierId, productOfferId);
    const productResponse = productResult.result?.data;

    if (productResponse) {
      setProductOffer(productResponse);
      setTitle([productResponse.supplier.name, productResponse.product.name]);
    }

    setLoading(false);
  };

  useEffect(() => {
    getAllMeasurements();
  }, []);

  useEffect(() => {
    fetchProducts();
  }, [tableParams]);

  const handleTableChange: TableProps<any>['onChange'] = (
    pagination,
    filters,
    sorter
  ) => {
    setTableParams({
      pagination,
      filters,
      sortOrder: Array.isArray(sorter) ? undefined : sorter.order,
      sortField: Array.isArray(sorter)
        ? undefined
        : sorter.order
          ? sorter.field
          : undefined,
    });

    // `dataSource` is useless since `pageSize` changed
    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setData([]);
    }
  };

  return {
    handleTableChange,
    loading: loading || measurementsLoading,
    columns,
    width: TABLE_WIDTH,
    data,
    total,
    productOffer,
    currencies,
  };
};

export default useSupplierProductPurchaseHistory;
