export const COMPANY_NOT_FOUND =
  'This company is not configured to use HivePix. Please check the name and try again, or contact your administrator.';

export const REQUIRED = 'Required field';

export const DUPLICATE_CUSTOMER = 'Duplicate customer';
export const DUPLICATE_PRODUCT = 'Duplicate product';

export const NO_PRODUCTS_PURCHASED = 'No products purchased';
export const NO_PRODUCTS_ADDED = 'No products added';
export const NO_PRODUCTS_ASSIGNED = 'No products assigned';

export const NO_RESULTS = 'No results found';

export const NO_SUPPLIERS_ADDED = 'No suppliers added';

export const NO_CUSTOMERS_ADDED = 'No customers added';
