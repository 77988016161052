import { Form } from 'antd';
import type { Rule } from 'antd/es/form';
import { LabelTooltipType } from 'antd/es/form/FormItemLabel';
import { PropsWithChildren } from 'react';
import { IconError } from 'src/components/icons/error/IconError';

export type TFormItemProps = PropsWithChildren<{
  className?: string;
  label?: string;
  name: string | (number | string)[];
  value?: string;
  rules?: Rule[];
  hasFeedback?: boolean;
  tooltip?: LabelTooltipType;
}>;

const FormItem = ({
  className,
  children,
  label,
  name,
  rules,
  value,
  hasFeedback = false,
  tooltip,
}: TFormItemProps) => {
  return (
    <Form.Item
      className={className}
      label={label}
      name={name}
      rules={rules}
      tooltip={tooltip}
      hasFeedback={
        hasFeedback &&
        !value && {
          icons: () => ({
            error: <IconError />,
          }),
        }
      }
    >
      {children}
    </Form.Item>
  );
};

export default FormItem;
