import { DefaultOptionType } from 'antd/es/select';
import { useEffect, useMemo, useState } from 'react';
import { BROADCASTNAME } from 'src/constants/broadcast';
import { initialProductTableParams } from 'src/constants/products';
import { useModalContext } from 'src/contexts/modal-context';
import { ITableParams } from 'src/types/generic';
import {
  TMappedProductListRecord,
  getMappedProductData,
  getProductColumnSetting,
} from 'src/utils/map-product-table';
import useProducts from './use-products';

type TUseCompanyProductsParams = {
  isActionAllowed: boolean;
  isSuppliers: boolean;
  debouncedSearch: string;
  openEdit: (record: TMappedProductListRecord) => void;
  currencies: DefaultOptionType[];
  uoms: DefaultOptionType[];
};

const useMyCompanyProducts = ({
  isActionAllowed,
  debouncedSearch,
  openEdit,
  currencies,
  uoms,
  isSuppliers,
}: TUseCompanyProductsParams) => {
  const { modalContextDispatch } = useModalContext();
  const { getProducts } = useProducts();

  const [data, setData] = useState<TMappedProductListRecord[]>([]);
  const [tableParams, setTableParams] = useState<ITableParams>(
    initialProductTableParams
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [total, setTotal] = useState<number>(0);

  const columns = useMemo(() => {
    const res = getProductColumnSetting({
      modalDispatch: modalContextDispatch,
      isActionAllowed,
      search: debouncedSearch,
      openEdit,
      currencies,
      uoms,
    });
    return res;
  }, [tableParams, isActionAllowed, debouncedSearch, uoms, currencies]);

  const fetchProducts = async () => {
    setLoading(true);
    const result = await getProducts({
      ...tableParams,
      search: debouncedSearch.length >= 3 ? debouncedSearch : '',
    });
    if (result.result?.data.data) {
      setData(getMappedProductData(result.result.data.data));
      setTotal(result.result.data.totalCount);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (!isSuppliers) {
      fetchProducts();
    }
    const broadcast = new BroadcastChannel(BROADCASTNAME);
    broadcast.onmessage = fetchProducts;
    return () => {
      broadcast.close();
    };
  }, [tableParams]);

  return {
    data,
    loading,
    total,
    columns,
    fetchProducts,
    setTableParams,
    setData,
    tableParams,
  };
};

export default useMyCompanyProducts;
