import { Button, Divider, Input, Tooltip, Typography } from 'antd';
import clsx from 'clsx';
import { ReactNode } from 'react';
import { IconInfoOutlined } from 'src/components/icons/info-outlined/IconInfoOutlined';
import { IconSearch } from 'src/components/icons/search/IconSearch';
import { useUserContext } from 'src/contexts/user-context';
import styles from './TableHeader.module.scss';
import { IconCancel } from 'src/components/icons/cancel/IconCancel';

const { Title } = Typography;

type TTableHeaderProps = {
  title?: string;
  search?: string | null;
  searchPlaceholder?: string;
  setSearch?: (value: string) => void;
  primaryActionTitle?: string;
  primaryActionIcon?: ReactNode;
  primaryAction?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => unknown;
  secondaryActionTitle?: string;
  secondaryActionIcon?: ReactNode;
  secondaryAction?: (
    event: React.MouseEvent<HTMLElement, MouseEvent>
  ) => unknown;
  noActions?: boolean;
  onlyPrimaryAction?: boolean;
  onlySecondaryAction?: boolean;
  bold?: boolean;
  isSearch?: boolean;
  customActionTitle?: string;
  customActionIcon?: ReactNode;
  customActionType?: 'primary';
  cusotmAction?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => unknown;
};

const TableHeader = ({
  title,
  searchPlaceholder,
  setSearch,
  search,
  primaryActionTitle,
  primaryAction,
  primaryActionIcon,
  secondaryActionTitle,
  secondaryAction,
  secondaryActionIcon,
  noActions = false,
  onlyPrimaryAction = false,
  onlySecondaryAction = false,
  bold = true,
  isSearch = true,
  cusotmAction,
  customActionIcon,
  customActionTitle,
  customActionType,
}: TTableHeaderProps) => {
  const {
    userContextState: { isAdmin, isAdvancedUser },
  } = useUserContext();

  const basicUser = !isAdmin && !isAdvancedUser;
  const isTitle = !!title;
  return (
    <div
      className={clsx(styles.component, {
        [styles.end]: !isTitle,
      })}
    >
      {isTitle && (
        <Title
          className={clsx(styles.title, {
            [styles.base]: !bold,
          })}
          level={5}
        >
          {title}
        </Title>
      )}
      <div className={styles.controls}>
        {isSearch && (
          <>
            <Input
              allowClear={{ clearIcon: <IconCancel height="16" width="16" /> }}
              prefix={
                <IconSearch width="16" height="16" className={styles.icon} />
              }
              suffix={
                !search ? (
                  <Tooltip title="Type at least 3 characters to start searching">
                    <IconInfoOutlined
                      width="16"
                      height="16"
                      className={styles.icon}
                    />
                  </Tooltip>
                ) : undefined
              }
              className={styles.search}
              placeholder={searchPlaceholder}
              onChange={(event) => {
                setSearch?.(event.target.value);
              }}
            />
            <Divider type="vertical" className={styles.divider} />
          </>
        )}
        {!!customActionTitle && (
          <>
            <Button
              icon={customActionIcon}
              type={customActionType}
              onClick={cusotmAction}
            >
              {customActionTitle}
            </Button>
            {!noActions && (
              <Divider type="vertical" className={styles.divider} />
            )}
          </>
        )}
        {!basicUser && !noActions && (
          <>
            {!onlyPrimaryAction && (
              <Button icon={secondaryActionIcon} onClick={secondaryAction}>
                {secondaryActionTitle}
              </Button>
            )}
            {!onlySecondaryAction && (
              <Button
                icon={primaryActionIcon}
                type="primary"
                onClick={primaryAction}
              >
                {primaryActionTitle}
              </Button>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default TableHeader;
