import { DefaultOptionType } from 'antd/es/select';
import { useState } from 'react';
import {
  HTTP,
  TResponse,
  useNetworkContext,
} from 'src/contexts/network-context';
import {
  TGetCurrenciesResponse,
  TGetUnitsOfMeasurementsResponse,
} from 'src/types/backend';

export type TGetUnitsOfMeasurements = () => Promise<
  TResponse<TGetUnitsOfMeasurementsResponse, unknown>
>;
export type TGetCurrencies = () => Promise<
  TResponse<TGetCurrenciesResponse, unknown>
>;

const useMeasurements = () => {
  const { fetch } = useNetworkContext();

  const [loading, setLoading] = useState(false);
  const [currencies, setCurrencies] = useState<DefaultOptionType[]>([]);
  const [uoms, setUoms] = useState<DefaultOptionType[]>([]);

  const getUnitsOfMeasurements: TGetUnitsOfMeasurements = async () => {
    const result = await fetch<TGetUnitsOfMeasurementsResponse, unknown>({
      url: '/measurements/unitOfMeasurement',
      method: HTTP.GET,
    });

    return result;
  };

  const getCurrencies: TGetCurrencies = async () => {
    const result = await fetch<TGetCurrenciesResponse, unknown>({
      url: '/measurements/currency',
      method: HTTP.GET,
    });

    return result;
  };

  const getAllMeasurements = async () => {
    setLoading(true);
    const curr = await getCurrencies();
    const currResponse = curr.result?.data;
    if (currResponse) {
      const formatted = currResponse.map((curr) => ({
        value: curr.value,
        label: curr.name,
      }));
      setCurrencies(formatted);
    }
    const measurements = await getUnitsOfMeasurements();
    const measurementsResponse = measurements.result?.data;
    if (measurementsResponse) {
      const formatted = measurementsResponse.map((uom) => ({
        value: uom.id,
        label: uom.shortName,
      }));
      setUoms(formatted);
    }
    setLoading(false);
  };
  return {
    getAllMeasurements,
    loading,
    uoms,
    currencies,
  };
};

export default useMeasurements;
