import { TableProps } from 'antd';
import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Mode } from 'src/components/products-drawer/ProductsDrawer';
import { useUserContext } from 'src/contexts/user-context';
import { ProtectedRoutes } from 'src/types/routes';
import { TMappedProductOfferRecord } from 'src/utils/map-assigned-product-table';
import { TMappedProductListRecord } from 'src/utils/map-product-table';
import useDebouncer from './use-debouncer';
import useMeasurements from './use-measurements';
import useMyCompanyProducts from './use-my-company-products';
import useMySuppliersProducts from './use-my-suppliers-products';

const TABLE_WIDTH = 1625;

const useProductsList = () => {
  const {
    loading: measurementsLoading,
    getAllMeasurements,
    uoms,
    currencies,
  } = useMeasurements();

  const {
    userContextState: { isAdmin, isAdvancedUser },
  } = useUserContext();

  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();
  const searchParam = searchParams.get('supplierList');
  const defaultTab = searchParam && searchParam === 'true' ? '2' : '1';
  const isSuppliers = searchParam && searchParam === 'true' ? true : false;
  const [open, setOpen] = useState(false);
  const [mode, setMode] = useState<Mode | null>(null);
  const [productId, setProductId] = useState<string | null>(null);
  const [search, setSearch] = useState<string | null>(null);
  const [debouncedSearch, setDebouncedSearch] = useState<string>('');
  const isActionAllowed = isAdmin || isAdvancedUser;

  const openAdd = () => {
    setMode(Mode.CREATE);
    setOpen(true);
  };

  const openRead = (
    record: TMappedProductListRecord | TMappedProductOfferRecord
  ) => {
    if (isSuppliers) {
      navigate(`${ProtectedRoutes.PRODUCTS}/${record.key}?isSupplier=true`);
      return;
    }
    navigate(`${ProtectedRoutes.PRODUCTS}/${record.key}`);
  };

  const openEdit = (record: TMappedProductListRecord) => {
    setProductId(record.key);
    setMode(Mode.EDIT);
    setOpen(true);
  };

  const {
    data,
    loading,
    total,
    columns,
    fetchProducts,
    setTableParams,
    setData,
    tableParams,
  } = useMyCompanyProducts({
    isActionAllowed,
    debouncedSearch,
    openEdit,
    currencies,
    uoms,
    isSuppliers,
  });

  const {
    supplierData,
    suppliersLoading,
    suppliersTotal,
    suppliersColumns,
    fetchSupplierProducts,
    setSupplierTableParams,
    setSupplierData,
    supplierTableParams,
  } = useMySuppliersProducts({
    debouncedSearch,
    currencies,
    uoms,
    isSuppliers,
  });

  const closeDrawer = () => {
    setProductId('');
    setOpen(false);
  };

  // Note: debounces search value
  useDebouncer(setDebouncedSearch, search || '');

  useEffect(() => {
    getAllMeasurements();
  }, []);

  useEffect(() => {
    if (
      debouncedSearch.length >= 3 ||
      (!debouncedSearch.length && search !== null)
    ) {
      if (isSuppliers) {
        fetchSupplierProducts();
      } else {
        fetchProducts();
      }
    }
  }, [debouncedSearch]);

  useEffect(() => {
    if (isSuppliers) {
      fetchSupplierProducts();
    } else {
      fetchProducts();
    }
  }, [isSuppliers]);

  const handleTableChange: TableProps<any>['onChange'] = (
    pagination,
    filters,
    sorter
  ) => {
    const params = {
      pagination,
      filters,
      sortOrder: Array.isArray(sorter) ? undefined : sorter.order,
      sortField: Array.isArray(sorter)
        ? undefined
        : sorter.order
          ? sorter.field
          : undefined,
    };
    if (isSuppliers) {
      setSupplierTableParams(params);
      // `dataSource` is useless since `pageSize` changed
      if (pagination.pageSize !== supplierTableParams.pagination?.pageSize) {
        setSupplierData([]);
      }
    } else {
      setTableParams(params);
      // `dataSource` is useless since `pageSize` changed
      if (pagination.pageSize !== tableParams.pagination?.pageSize) {
        setData([]);
      }
    }
  };

  const handleTabChange = (key: string) => {
    if (key === '2') {
      setSearchParams((params) => {
        params.set('supplierList', 'true');
        return params;
      });
    } else {
      setSearchParams((params) => {
        params.set('supplierList', 'false');
        return params;
      });
    }
  };

  return {
    columns,
    suppliersColumns,
    data,
    supplierData,
    total,
    suppliersTotal,
    handleTableChange,
    handleTabChange,
    setSearch,
    loading: loading || measurementsLoading,
    suppliersLoading: suppliersLoading || measurementsLoading,
    width: TABLE_WIDTH,
    debouncedSearch,
    openAdd,
    openRead,
    closeDrawer,
    mode,
    productId,
    open,
    search,
    defaultTab,
    isSuppliers,
  };
};

export default useProductsList;
