import { TableProps } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { BROADCASTNAME } from 'src/constants/broadcast';
import { useMessageContext } from 'src/contexts/message-context';
import { useModalContext } from 'src/contexts/modal-context';
import { ICompany } from 'src/types/general';
import { ITableParams } from 'src/types/generic';
import {
  TMappedCompanyRecord,
  getCompanyColumnSetting,
  getMappedCompanyData,
} from 'src/utils/map-companies-table';
import useCompanies from './use-companies';
import useDebouncer from './use-debouncer';

const TABLE_WIDTH = 1510;

const useCompaniesList = (onClose: () => void) => {
  const { getCompanies, addSupplier } = useCompanies();

  const { showSuccessMessage, showErrorMessage } = useMessageContext();

  const { modalContextDispatch } = useModalContext();

  const [data, setData] = useState<ICompany[]>([]);
  const [mappedData, setMappedData] = useState<TMappedCompanyRecord[]>([]);
  const [selectedId, setSelectedId] = useState<number | null>(null);
  const [open, setOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [search, setSearch] = useState<string>('');
  const [debouncedSearch, setDebouncedSearch] = useState<string>('');
  const [creating, setCreating] = useState<boolean>(false);
  const [tableParams, setTableParams] = useState<ITableParams>({
    pagination: {
      current: 1,
      pageSize: 10,
    },
    sortField: 'gln',
    sortOrder: 'ascend',
  });

  const columns = useMemo(() => {
    const res = getCompanyColumnSetting<TMappedCompanyRecord>({
      modalDispatch: modalContextDispatch,
      search: debouncedSearch,
    });
    return res;
  }, [tableParams, debouncedSearch]);

  // Note: debounces search value
  useDebouncer(setDebouncedSearch, search);

  const fetchCompanies = async () => {
    if (debouncedSearch.length < 3) {
      setData([]);
      return;
    }

    setLoading(true);
    const result = await getCompanies({
      ...tableParams,
      search: debouncedSearch,
    });

    if (result.result?.data.data) {
      setData(result.result.data.data);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchCompanies();
  }, [debouncedSearch, tableParams]);

  const handleTableChange: TableProps<any>['onChange'] = (
    pagination,
    filters,
    sorter
  ) => {
    setTableParams({
      pagination,
      filters,
      sortOrder: Array.isArray(sorter) ? undefined : sorter.order,
      sortField: Array.isArray(sorter)
        ? undefined
        : sorter.order
          ? sorter.field
          : undefined,
    });
  };

  useEffect(() => {
    setMappedData(getMappedCompanyData(data, selectedId));
  }, [data, selectedId]);

  const createSupplier = async () => {
    if (selectedId === null) {
      return;
    }
    setCreating(true);
    const name = data[selectedId].name;
    if (!name) {
      return;
    }
    const res = await addSupplier(name);
    if (!res.errors) {
      showSuccessMessage(`Supplier “${name}” added`);
      const broadcast = new BroadcastChannel(BROADCASTNAME);
      broadcast.postMessage('refresh');
      broadcast.close();
    } else {
      showErrorMessage(`Supplier “${name}” not added`);
    }
    setCreating(false);
  };

  const close = () => {
    setData([]);
    setSearch('');
    onClose();
  };

  return {
    handleTableChange,
    setSearch,
    loading,
    columns,
    width: TABLE_WIDTH,
    data: mappedData,
    debouncedSearch,
    open,
    setOpen,
    selectedId,
    setSelectedId,
    creating,
    createSupplier,
    search,
    close,
  };
};

export default useCompaniesList;
