import { Drawer, Spin } from 'antd';
import { useMemo } from 'react';
import useProductCrudForm from 'src/hooks/use-product-crud-form';
import ProductDrawerForm from './form/ProductDrawerForm';
import Loader from '../loader/Loader';

export enum Mode {
  CREATE = 'CREATE',
  EDIT = 'EDIT',
}

type TProductsdraggerProps = {
  open: boolean;
  onClose: () => void;
  mode: Mode | null;
  productId: string | null;
};

const ProductsDrawer = ({
  open,
  onClose,
  mode,
  productId,
}: TProductsdraggerProps) => {
  const title = useMemo(
    () =>
      mode === Mode.CREATE
        ? 'Add product'
        : mode === Mode.EDIT
          ? 'Edit product'
          : 'View product',
    [mode]
  );

  const {
    form,
    name,
    materialNumber,
    partNumber,
    barCode,
    externalEpdUrl,
    epdFile,
    createdAt,
    createdByName,
    modifiedAt,
    modifiedByName,
    draggerProps,
    loading,
    isChanged,
    submit,
    closeDrawer,
    loadingText,
    setEpdFile,
    currencies,
    uoms,
    rrpPrice,
    currency,
    quantity,
    unitOfMeasurementId,
  } = useProductCrudForm(productId, mode, onClose, open);

  return (
    <>
      <Drawer
        title={title}
        open={open}
        onClose={closeDrawer}
        width={495}
        footer={<Loader visible={loading} text={loadingText} />}
      >
        <ProductDrawerForm
          mode={mode}
          onClose={closeDrawer}
          draggerProps={draggerProps}
          form={form}
          name={name}
          materialNumber={materialNumber}
          partNumber={partNumber}
          barCode={barCode}
          externalEpdUrl={externalEpdUrl}
          epdFile={epdFile}
          createdAt={createdAt}
          createdByName={createdByName}
          modifiedAt={modifiedAt}
          modifiedByName={modifiedByName}
          isChanged={isChanged}
          reupload={() => setEpdFile(null)}
          submit={submit}
          rrpPrice={rrpPrice}
          quantity={quantity}
          currency={currency}
          currencies={currencies}
          uoms={uoms}
          unitOfMeasurement={unitOfMeasurementId}
        />
      </Drawer>
    </>
  );
};

export default ProductsDrawer;
