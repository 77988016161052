import { useMemo } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { IconBulb } from 'src/components/icons/bulb/IconBulb';
import { IconDashboard } from 'src/components/icons/dashboard/IconDashboard';
import { IconHandshake } from 'src/components/icons/handshake/IconHandshake';
import { IconHive } from 'src/components/icons/hive/IconHive';
import { IconHub } from 'src/components/icons/hub/IconHub';
import { IconInventory } from 'src/components/icons/inventory/IconInventory';
import { useNavigationContext } from 'src/contexts/navigation-context';
import { ProtectedRoutes, RouteTitle } from 'src/types/routes';

const useNavigationHeader = () => {
  const [searchParams] = useSearchParams();
  const isSupplier = searchParams.get('isSupplier') === 'true';

  const location = useLocation();

  const locationBreadcrumb = location.pathname.slice(1).split('/');

  const parentLocation = `/${locationBreadcrumb[0]}` as ProtectedRoutes;

  const title = RouteTitle[parentLocation];

  const { title: subTitles } = useNavigationContext();

  const isProductsRoute = title === RouteTitle[ProtectedRoutes.PRODUCTS];

  const newProductTitle = isSupplier
    ? `My suppliers' products`
    : 'My company products';
  const newParentLocation = isSupplier
    ? `${parentLocation}?supplierList=true`
    : parentLocation;

  const titleOverride = isProductsRoute ? newProductTitle : title;
  const parentLocationOverride = isProductsRoute
    ? newParentLocation
    : parentLocation;

  const icon = useMemo(() => {
    switch (parentLocation) {
      case ProtectedRoutes.DASHBOARD:
        return <IconDashboard height="16" width="16" />;
      case ProtectedRoutes.HIVE:
        return <IconHive height="16" width="16" />;
      case ProtectedRoutes.SUPPLIERS:
        return <IconHub height="16" width="16" />;
      case ProtectedRoutes.CUSTOMERS:
        return <IconHandshake height="16" width="16" />;
      case ProtectedRoutes.PRODUCTS:
        return <IconInventory height="16" width="16" />;
      case ProtectedRoutes.INSIGHTS:
        return <IconBulb height="16" width="16" />;
    }
  }, [parentLocation]);

  const items = locationBreadcrumb.map((_, i) => ({
    title: i === 0 ? titleOverride : subTitles?.[i - 1] || '-',
  }));

  const getLinkTo = (i: number) => {
    if (i === 0) {
      return parentLocationOverride;
    }
    return locationBreadcrumb.reduce((acc, curr, index) => {
      if (index > i) {
        return acc;
      }
      return acc + `/${curr}`;
    }, '');
  };

  return { icon, title, locationBreadcrumb, getLinkTo, items };
};

export default useNavigationHeader;
