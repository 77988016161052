import { ColumnsType } from 'antd/es/table';
import { IconSort, SortOrder } from 'src/components/icons/sort/IconSort';
import Cell from 'src/components/table/cell/Cell';
import Url from 'src/components/table/url/Url';
import File from 'src/components/table/file/File';
import { ArrayElement } from 'src/types/generic';
import { formatDate } from './date-formatter';
import { IProduct } from 'src/types/general';
import { Button, Tooltip } from 'antd';
import { IconEdit } from 'src/components/icons/edit/IconEdit';
import { IconArchive } from 'src/components/icons/archive/IconArchive';
import styles from 'src/styles/actions.module.scss';
import {
  ActionType,
  ModalContextActions,
  TModalContextStateAction,
} from 'src/contexts/modal-context';
import Highlighter from 'src/components/table/highlighter/Highlighter';
import { DefaultOptionType } from 'antd/es/select';
import { formatNumber } from './number-formatter';

const properties: Array<keyof TMappedProductListRecord> = [
  'name',
  'materialNumber',
  'partNumber',
  'barCode',
  'epdFile',
  'externalEpdUrl',
  'rrpPrice',
  'currency',
  'quantity',
  'unitOfMeasurement',
  'createdAt',
  'createdByName',
  'modifiedAt',
  'modifiedByName',
  'actions',
];

export type TMappedProductListRecord = Omit<
  IProduct,
  'canEdit' | 'canDelete' | 'id' | 'unitOfMeasurementId'
> & {
  actions: Pick<IProduct, 'canDelete' | 'canEdit'>;
  key: string;
};

export const getMappedProductData = (
  data: IProduct[]
): TMappedProductListRecord[] => {
  return data.map((row) => {
    const res: TMappedProductListRecord = {
      key: row.id,
      name: row.name,
      materialNumber: row.materialNumber,
      partNumber: row.partNumber,
      barCode: row.barCode,
      epdFile: row.epdFile,
      externalEpdUrl: row.externalEpdUrl,
      createdAt: row.createdAt,
      createdByName: row.createdByName,
      currency: row.currency,
      rrpPrice: row.rrpPrice,
      unitOfMeasurement: row.unitOfMeasurementId,
      quantity: row.quantity,
      modifiedAt: row.modifiedAt,
      modifiedByName: row.modifiedByName,
      actions: {
        canDelete: row.canDelete,
        canEdit: row.canEdit,
      },
    };
    return res;
  });
};

type TGetProductColumnSettingParams = {
  modalDispatch: React.Dispatch<TModalContextStateAction>;
  isActionAllowed: boolean;
  search: string;
  openEdit: (record: TMappedProductListRecord) => void;
  currencies: DefaultOptionType[];
  uoms: DefaultOptionType[];
};

export const getProductColumnSetting = ({
  modalDispatch,
  isActionAllowed,
  search,
  openEdit,
  currencies,
  uoms,
}: TGetProductColumnSettingParams) => {
  const result: ColumnsType<TMappedProductListRecord> = properties.map(
    (property): ArrayElement<ColumnsType<TMappedProductListRecord>> => {
      switch (property) {
        case 'name':
          return {
            title: 'Product name',
            fixed: 'left',
            width: 240,
            key: property,
            dataIndex: property,
            ellipsis: true,
            sorter: true,
            showSorterTooltip: false,
            sortIcon: (props) => (
              <IconSort
                width="16"
                height="16"
                order={props.sortOrder as SortOrder}
              />
            ),
            render: (value) => {
              return (
                <Cell isValue={!!value}>
                  <Highlighter value={value} search={search} />
                </Cell>
              );
            },
          };
        case 'materialNumber':
          return {
            title: 'Material number',
            width: 155,
            ellipsis: true,
            key: property,
            dataIndex: property,
            sorter: true,
            showSorterTooltip: false,
            sortIcon: (props) => (
              <IconSort
                width="16"
                height="16"
                order={props.sortOrder as SortOrder}
              />
            ),
            render: (value) => {
              return (
                <Cell isValue={!!value}>
                  <Highlighter value={value} search={search} />
                </Cell>
              );
            },
          };
        case 'partNumber':
          return {
            title: 'Part number',
            width: 155,
            ellipsis: true,
            key: property,
            dataIndex: property,
            sorter: true,
            showSorterTooltip: false,
            sortIcon: (props) => (
              <IconSort
                width="16"
                height="16"
                order={props.sortOrder as SortOrder}
              />
            ),
            render: (value) => {
              return (
                <Cell isValue={!!value}>
                  <Highlighter value={value} search={search} />
                </Cell>
              );
            },
          };
        case 'barCode':
          return {
            title: 'Barcode',
            width: 155,
            ellipsis: true,
            key: property,
            dataIndex: property,
            sorter: true,
            showSorterTooltip: false,
            sortIcon: (props) => (
              <IconSort
                width="16"
                height="16"
                order={props.sortOrder as SortOrder}
              />
            ),
            render: (value) => {
              return (
                <Cell isValue={!!value}>
                  <Highlighter value={value} search={search} />
                </Cell>
              );
            },
          };
        case 'epdFile':
          return {
            title: 'EPD file',
            width: 155,
            ellipsis: true,
            key: property,
            dataIndex: property,
            sorter: true,
            showSorterTooltip: false,
            sortIcon: (props) => (
              <IconSort
                width="16"
                height="16"
                order={props.sortOrder as SortOrder}
              />
            ),
            render: (value) => {
              return (
                <Cell isValue={!!value?.fileName}>
                  <File file={value} />
                </Cell>
              );
            },
          };
        case 'externalEpdUrl':
          return {
            title: 'EPD link',
            width: 155,
            ellipsis: true,
            key: property,
            dataIndex: property,
            sorter: true,
            showSorterTooltip: false,
            sortIcon: (props) => (
              <IconSort
                width="16"
                height="16"
                order={props.sortOrder as SortOrder}
              />
            ),
            render: (value) => {
              return (
                <Cell isValue={!!value}>
                  <Url value={value} />
                </Cell>
              );
            },
          };
        case 'rrpPrice':
          return {
            title: 'RRP',
            width: 120,
            ellipsis: true,
            key: property,
            dataIndex: property,
            sorter: true,
            showSorterTooltip: false,
            sortIcon: (props) => (
              <IconSort
                width="16"
                height="16"
                order={props.sortOrder as SortOrder}
              />
            ),
            render: (value) => {
              return <Cell isValue={!!value}>{formatNumber(value)}</Cell>;
            },
          };
        case 'currency':
          return {
            title: 'Currency',
            width: 96,
            ellipsis: true,
            key: property,
            dataIndex: property,
            sorter: true,
            showSorterTooltip: false,
            sortIcon: (props) => (
              <IconSort
                width="16"
                height="16"
                order={props.sortOrder as SortOrder}
              />
            ),
            render: (value) => {
              return (
                <Cell isValue={!!value}>
                  {currencies.find((el) => el.value === value)?.label}
                </Cell>
              );
            },
          };
        case 'quantity':
          return {
            title: 'Qty',
            width: 70,
            ellipsis: true,
            key: property,
            dataIndex: property,
            sorter: true,
            showSorterTooltip: false,
            sortIcon: (props) => (
              <IconSort
                width="16"
                height="16"
                order={props.sortOrder as SortOrder}
              />
            ),
            render: (value) => {
              return <Cell isValue={!!value}>{formatNumber(value)}</Cell>;
            },
          };
        case 'unitOfMeasurement':
          return {
            title: 'UoM',
            width: 70,
            ellipsis: true,
            key: property,
            dataIndex: property,
            sorter: true,
            showSorterTooltip: false,
            sortIcon: (props) => (
              <IconSort
                width="16"
                height="16"
                order={props.sortOrder as SortOrder}
              />
            ),
            render: (value) => {
              return (
                <Cell isValue={!!value}>
                  {uoms.find((el) => el.value === value)?.label}
                </Cell>
              );
            },
          };
        case 'createdAt':
          return {
            title: 'Created on',
            width: 120,
            key: property,
            dataIndex: property,
            sorter: true,
            showSorterTooltip: false,
            defaultSortOrder: 'descend',
            sortIcon: (props) => (
              <IconSort
                width="16"
                height="16"
                order={props.sortOrder as SortOrder}
              />
            ),
            render: (value) => {
              return <Cell isValue={!!value}>{formatDate(value)}</Cell>;
            },
          };
        case 'createdByName':
          return {
            title: 'Created by',
            width: 155,
            ellipsis: true,
            key: property,
            dataIndex: property,
            sorter: true,
            showSorterTooltip: false,
            sortIcon: (props) => (
              <IconSort
                width="16"
                height="16"
                order={props.sortOrder as SortOrder}
              />
            ),
            render: (value) => {
              return <Cell isValue={!!value}>{String(value)}</Cell>;
            },
          };
        case 'modifiedAt':
          return {
            title: 'Modified on',
            width: 120,
            key: property,
            dataIndex: property,
            sorter: true,
            showSorterTooltip: false,
            sortIcon: (props) => (
              <IconSort
                width="16"
                height="16"
                order={props.sortOrder as SortOrder}
              />
            ),
            render: (value) => {
              return <Cell isValue={!!value}>{formatDate(value)}</Cell>;
            },
          };
        case 'modifiedByName':
          return {
            title: 'Modified by',
            width: 155,
            ellipsis: true,
            key: property,
            dataIndex: property,
            sorter: true,
            showSorterTooltip: false,
            sortIcon: (props) => (
              <IconSort
                width="16"
                height="16"
                order={props.sortOrder as SortOrder}
              />
            ),
            render: (value) => {
              return <Cell isValue={!!value}>{String(value)}</Cell>;
            },
          };
        case 'actions':
          if (!isActionAllowed) {
            return { hidden: true };
          }
          return {
            key: property,
            dataIndex: property,
            fixed: 'right',
            width: 60,
            render: (value, record) => {
              return (
                <div className={styles.component}>
                  {value.canEdit && (
                    <Tooltip title="Edit">
                      <Button
                        size="small"
                        type="text"
                        onClick={(e) => {
                          e.stopPropagation();
                          openEdit(record);
                        }}
                        icon={
                          <IconEdit
                            color={styles.iconFill}
                            height="16"
                            width="16"
                          />
                        }
                      />
                    </Tooltip>
                  )}
                  {value.canDelete && (
                    <Tooltip title="Archive">
                      <Button
                        size="small"
                        type="text"
                        onClick={(e) => {
                          e.stopPropagation();
                          modalDispatch({
                            type: ModalContextActions.SHOW_MODAL,
                            value: {
                              title: 'Archive product',
                              description: `Product ”${record.name}” will be removed from the list.`,
                              actionText: 'Archive',
                              action: ActionType.ARCHIVE,
                              dataId: record.key,
                              dataName: record.name || '-',
                            },
                          });
                        }}
                        icon={
                          <IconArchive
                            color={styles.iconFill}
                            height="16"
                            width="16"
                          />
                        }
                      />
                    </Tooltip>
                  )}
                </div>
              );
            },
          };
        default:
          return { hidden: true };
      }
    }
  );
  return result;
};
