import { Typography } from 'antd';
import { PropsWithChildren } from 'react';
import styles from './Email.module.scss';

const { Text } = Typography;

const Email = ({ children }: PropsWithChildren) => {
  return <Text className={styles.component}>{children}</Text>;
};

export default Email;
