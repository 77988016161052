import { ICustomer, IProduct } from 'src/types/general';
import styles from 'src/components/assign-modal/form/AssignForm.module.scss';
import { Typography } from 'antd';

export const getCustomerSelectOptions = (
  customers: ICustomer[],
  value?: string | number
) => {
  const customer = customers.find((customer) => customer.id === value);
  if (!customer) {
    return null;
  }
  return (
    <div className={styles.option}>
      <Typography.Text className={styles.noMargin}>
        {customer.name}
      </Typography.Text>
      <Typography.Text className={styles.optionDescription}>
        {customer.gln || '–'} | {customer.industry || '–'} |{' '}
        {customer.address || '–'}
      </Typography.Text>
    </div>
  );
};

export const getProductSelectOptions = (
  products: IProduct[],
  value?: string | number
) => {
  const product = products.find((product) => product.id === value);
  if (!product) {
    return null;
  }
  return (
    <div className={styles.option}>
      <Typography.Text className={styles.noMargin}>
        {product.name}
      </Typography.Text>
      <Typography.Text className={styles.optionDescription}>
        {product.materialNumber || '–'} | {product.partNumber || '–'} |{' '}
        {product.barCode || '–'}
      </Typography.Text>
    </div>
  );
};
