import { DefaultOptionType } from 'antd/es/select';
import { ColumnsType } from 'antd/es/table';
import { IconSort, SortOrder } from 'src/components/icons/sort/IconSort';
import Cell from 'src/components/table/cell/Cell';
import File from 'src/components/table/file/File';
import { IMappedPurchaseHistory, IPurchaseHistory } from 'src/types/general';
import { ArrayElement } from 'src/types/generic';
import { formatDate } from './date-formatter';
import { formatNumber } from './number-formatter';

const properties: (keyof IMappedPurchaseHistory | 'actions')[] = [
  'invoice.purchaseOrderNumber',
  'invoice.fileName',
  'invoice.invoiceDate',
  'quantity',
  'unitOfMeasurement',
  'totalAmount',
  'currency',
  'productOffer.price',
  'productOffer.currency',
  'project.personalId',
  'project.name',
];

export type TMappedPurchaseHistoryRecord = IMappedPurchaseHistory & {
  key: string;
};

type TGetAssignedProductColumnsParams = {
  currencies: DefaultOptionType[];
  uoms: DefaultOptionType[];
};

export const getMappedPurchaseHistoryData = (
  data: IPurchaseHistory[]
): TMappedPurchaseHistoryRecord[] => {
  return data.map((row) => {
    const res: TMappedPurchaseHistoryRecord = {
      key: row.id,
      'invoice.purchaseOrderNumber': row.invoice?.purchaseOrderNumber || null,
      'invoice.fileName': row.invoice,
      'invoice.invoiceDate': row.invoice?.invoiceDate || null,
      quantity: row.quantity,
      unitOfMeasurement: row.unitOfMeasurementId,
      totalAmount: row.totalAmount,
      currency: row.currency,
      'productOffer.price': row.productOffer.price,
      'productOffer.currency': row.productOffer.currency,
      'project.personalId': row.project.personalId,
      'project.name': row.project.name,
    };
    return res;
  });
};

export const getPurchaseHistoryColumnSetting = function ({
  currencies,
  uoms,
}: TGetAssignedProductColumnsParams) {
  const result = properties.map(
    (property): ArrayElement<ColumnsType<TMappedPurchaseHistoryRecord>> => {
      switch (property) {
        case 'invoice.purchaseOrderNumber':
          return {
            title: 'Order #',
            width: 120,
            key: property,
            fixed: 'left',
            ellipsis: true,
            dataIndex: property,
            sorter: true,
            showSorterTooltip: false,
            sortIcon: (props) => (
              <IconSort
                width="16"
                height="16"
                order={props.sortOrder as SortOrder}
              />
            ),
            render: (value) => {
              return <Cell isValue={!!value}>{value}</Cell>;
            },
          };
        case 'invoice.fileName':
          return {
            title: 'Invoice name',
            width: 200,
            ellipsis: true,
            key: property,
            fixed: 'left',
            dataIndex: property,
            sorter: true,
            showSorterTooltip: false,
            sortIcon: (props) => (
              <IconSort
                width="16"
                height="16"
                order={props.sortOrder as SortOrder}
              />
            ),
            render: (value) => {
              return (
                <Cell isValue={!!value}>
                  <File file={value} />
                </Cell>
              );
            },
          };
        case 'invoice.invoiceDate':
          return {
            title: 'Invoice date',
            width: 110,
            ellipsis: true,
            key: property,
            fixed: 'left',
            dataIndex: property,
            sorter: true,
            showSorterTooltip: false,
            defaultSortOrder: 'descend',
            sortIcon: (props) => (
              <IconSort
                width="16"
                height="16"
                order={props.sortOrder as SortOrder}
              />
            ),
            render: (value) => {
              //   <Typography.Text
              //   className={clsx(styles.noMargin, {
              //     [styles.expired]: new Date(value) < new Date(),
              //   })}
              // >
              //   {formatDate(value)}
              // </Typography.Text>
              return <Cell isValue={!!value}>{formatDate(value)}</Cell>;
            },
          };
        case 'quantity':
          return {
            title: 'Invoice Qty',
            width: 110,
            align: 'right',
            ellipsis: true,
            key: property,
            dataIndex: property,
            sorter: true,
            showSorterTooltip: false,
            sortIcon: (props) => (
              <IconSort
                width="16"
                height="16"
                order={props.sortOrder as SortOrder}
              />
            ),
            render: (value) => {
              return <Cell isValue={!!value}>{formatNumber(value)}</Cell>;
            },
          };
        case 'unitOfMeasurement':
          return {
            title: 'Invoice UoM',
            width: 110,
            ellipsis: true,
            key: property,
            dataIndex: property,
            sorter: true,
            showSorterTooltip: false,
            sortIcon: (props) => (
              <IconSort
                width="16"
                height="16"
                order={props.sortOrder as SortOrder}
              />
            ),
            render: (value) => {
              return (
                <Cell isValue={!!value}>
                  {uoms.find((el) => el.value === value)?.label}
                </Cell>
              );
            },
          };
        case 'totalAmount':
          return {
            title: 'Invoice amount',
            width: 128,
            align: 'right',
            ellipsis: true,
            key: property,
            dataIndex: property,
            sorter: true,
            showSorterTooltip: false,
            sortIcon: (props) => (
              <IconSort
                width="16"
                height="16"
                order={props.sortOrder as SortOrder}
              />
            ),
            render: (value) => {
              return <Cell isValue={!!value}>{formatNumber(value)}</Cell>;
            },
          };
        case 'currency':
          return {
            title: 'Currency',
            width: 92,
            ellipsis: true,
            key: property,
            dataIndex: property,
            sorter: true,
            showSorterTooltip: false,
            sortIcon: (props) => (
              <IconSort
                width="16"
                height="16"
                order={props.sortOrder as SortOrder}
              />
            ),
            render: (value) => {
              return (
                <Cell isValue={!!value}>
                  {currencies.find((el) => el.value === value)?.label}
                </Cell>
              );
            },
          };
        case 'productOffer.price':
          return {
            title: 'List price',
            width: 92,
            align: 'right',
            ellipsis: true,
            key: property,
            dataIndex: property,
            sorter: true,
            showSorterTooltip: false,
            sortIcon: (props) => (
              <IconSort
                width="16"
                height="16"
                order={props.sortOrder as SortOrder}
              />
            ),
            render: (value) => {
              return <Cell isValue={!!value}>{formatNumber(value)}</Cell>;
            },
          };
        case 'productOffer.currency':
          return {
            title: 'Currency',
            width: 92,
            ellipsis: true,
            key: property,
            dataIndex: property,
            sorter: true,
            showSorterTooltip: false,
            sortIcon: (props) => (
              <IconSort
                width="16"
                height="16"
                order={props.sortOrder as SortOrder}
              />
            ),
            render: (value) => {
              return (
                <Cell isValue={!!value}>
                  {currencies.find((el) => el.value === value)?.label}
                </Cell>
              );
            },
          };
        case 'project.personalId':
          return {
            title: 'Project ID',
            width: 136,
            key: property,
            ellipsis: true,
            dataIndex: property,
            sorter: true,
            showSorterTooltip: false,
            sortIcon: (props) => (
              <IconSort
                width="16"
                height="16"
                order={props.sortOrder as SortOrder}
              />
            ),
            render: (value) => {
              return <Cell isValue={!!value}>{value}</Cell>;
            },
          };
        case 'project.name':
          return {
            title: 'Project name',
            width: 200,
            key: property,
            ellipsis: true,
            dataIndex: property,
            sorter: true,
            showSorterTooltip: false,
            sortIcon: (props) => (
              <IconSort
                width="16"
                height="16"
                order={props.sortOrder as SortOrder}
              />
            ),
            render: (value) => {
              return <Cell isValue={!!value}>{value}</Cell>;
            },
          };
        default:
          return { hidden: true };
      }
    }
  );
  return result;
};
