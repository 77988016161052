export const issueTypeOptions = [
  {
    label: 'Technical issues (errors, bugs)',
    value: '1',
  },
  {
    label: 'Data issues',
    value: '2',
  },
  {
    label: 'General inquiry',
    value: '3',
  },
  {
    label: 'Feature request or suggestion',
    value: '4',
  },
  {
    label: 'Other',
    value: '5',
  },
];

export const issuePriorityOptions = [
  {
    label: 'Minor',
    value: '1',
  },
  {
    label: 'Trivial',
    value: '2',
  },
  {
    label: 'Major',
    value: '3',
  },
  {
    label: 'Critical',
    value: '4',
  },
  {
    label: 'Blocker',
    value: '5',
  },
];
